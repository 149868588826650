import { IconColumnDataModel } from '@roadrecord/grid';
import { PeriodicalRecommendationStatusEnum } from '../model/periodical-recommendation-status.enum';

export const setIconFn = (_this: any): any => (model: any): string | IconColumnDataModel => {
  const translatePrefix = 'PERIODICAL.STATUS.ICON.TOOLTIP.';
  switch (model.status) {
    case PeriodicalRecommendationStatusEnum.NEW:
    case PeriodicalRecommendationStatusEnum.IN_PROGRESS:
      return {
        value: 'progress-check',
        cssClassName: 'progress',
        tooltipTextTranslateKey: `${translatePrefix}IN_PROGRESS`,
      };
    case PeriodicalRecommendationStatusEnum.FINISHED_OK:
    case PeriodicalRecommendationStatusEnum.FINISHED_WITH_CORRECTION:
      return {
        value: 'check',
        cssClassName: 'success',
        tooltipTextTranslateKey: `${translatePrefix}SUCCESS`,
      };
    case PeriodicalRecommendationStatusEnum.FINISHED_BUT_NOT_ACCEPTABLE:
    case PeriodicalRecommendationStatusEnum.FINISHED_WITH_CHECKER_ERROR:
    case PeriodicalRecommendationStatusEnum.RECOMMENDATION_STATUS_ERROR:
      return {
        value: 'alert',
        cssClassName: 'error',
        tooltipTextTranslateKey: `${translatePrefix}UNSUCCESSFUL`,
      };
    default:
      return '';
  }
};
