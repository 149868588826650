import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { fadeIn2XFastEnter, fadeInEnter, fadeOut2XFastLeave } from '@roadrecord/animations';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { ngfDrop } from 'angular-file';
import { take } from 'rxjs/operators';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportButtonConfig, UniversalImportConfig } from '../../../model/universal-import-config';
import { AcceptedFileTypePipe } from './accepted-file-type.pipe';
import {
  CsvImportFileType,
  ExcelImportFileType,
  getAllFileTypeWithExtensionToUniversalImport,
  getFileTypeWithExtension,
  AppleOrAndroidTimelineJsonImportFileType,
  GoogleTimelineJsonImportFileType,
} from '@roadrecord/worker/universal-import';

@Component({
  selector: 'rr-upload-panel-step',
  templateUrl: './upload-panel-step.component.html',
  styleUrls: ['./upload-panel-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInEnter, fadeOut2XFastLeave, fadeIn2XFastEnter, fadeOut2XFastLeave],
})
export class UploadPanelStepComponent implements OnChanges, OnInit {
  @Input() selectedSourceType: UniversalImportButtonConfig<any>;
  @Output() loading = new EventEmitter<boolean>();
  @Output() backToSelectSource = new EventEmitter();
  @ViewChildren(ngfDrop) private ngfDrop: QueryList<ngfDrop>;
  @HostBinding('class.disabled')
  fileDropDisabled = false;
  fileControl: AbstractControl;
  validDraggingFile: boolean;
  uploadedFileError: string;
  selectedFile: File;
  files: any;
  // fileAcceptExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,.xls';
  // fileAcceptCSV = 'text/plain,text/csv,.csv';
  // fileAcceptJSON = 'application/json,.json';
  fileAcceptDefault = getAllFileTypeWithExtensionToUniversalImport();
  fileAccept = this.fileAcceptDefault;
  maxFileSize = 0;
  private startedHideControl: AbstractControl;
  readonly fileUploadTranslatePrefix = 'FILE_UPLOAD.';

  importSampleUrl: string | null = null;

  selectedSourceTypeTranslateKey: string;
  universalUploadType = 'universal';

  @ViewChildren('downloadFileAnchor') private downloadFileAnchor: QueryList<ElementRef<HTMLAnchorElement>>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    @Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>
  ) {}

  private _form: FormGroup;

  get form(): FormGroup {
    return this._form;
  }

  @Input()
  set form(value: FormGroup) {
    this._form = value;
    if (!isNil(value)) {
      this.fileControl = this._form.get('file');
      this.startedHideControl = this._form.get('started');
    }
  }

  onFileChange(file: File) {
    this.validDraggingFile = true;
    delete this.uploadedFileError;
    this.selectedFile = file;
    this.fileControl.patchValue(file);
  }

  onLastInvalidsChange($event: { file: File; type: string }[]) {
    if (Array.isArray($event) && $event.length === 1) {
      if (this.selectedSourceType.controllerType === 'GOOGLE_TIMELINE_JSON' && this.isZIP($event[0].file)) {
        this.onFileChange($event[0].file);
      } else {
        this.fileControl.patchValue(null);
        const errorPrefix = 'FILE_UPLOAD.SELECT_FILE.INVALID.';
        this.uploadedFileError =
          $event[0].type === 'fileSize'
            ? `${errorPrefix}SIZE`
            : `${errorPrefix}${new AcceptedFileTypePipe().transform(
                this.selectedSourceType.acceptedFileTypes,
                'TYPE_JUST_CSV',
                'TYPE_JUST_XLSX',
                'TYPE_JUST_JSON',
                'TYPE'
              )}`;
      }
      this.cdr.markForCheck();
    }
  }

  onValidDrag($event: boolean) {
    this.validDraggingFile = $event;
    if (this.uploadedFileError !== undefined) {
      delete this.uploadedFileError;
    }
  }

  startImport() {
    // jelzes kivulre hogy mehet tovabb
    this.startedHideControl.patchValue(true);
    this.fileDropDisabled = true;
    this.loading.next(true);
    this.startedHideControl.valueChanges.pipe(take(1)).subscribe(v => {
      if (v === false) {
        this.loading.next(false);
        this.fileDropDisabled = false;
        this.resetFileDropFiles();
        this.cdr.markForCheck();
      }
    });
  }

  private resetFileDropFiles() {
    this.files = [];
    delete this.selectedFile;
    delete this.validDraggingFile;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['selectedSourceType'])) {
      this.fileAccept = this.selectedSourceType.acceptedFileTypes
        .map(acceptedFileType => {
          switch (acceptedFileType) {
            case 'CSV':
              return getFileTypeWithExtension(CsvImportFileType);
            case 'ZIP':
            case 'JSON':
              if (this.selectedSourceType.controllerType === 'GOOGLE_TIMELINE_APPLE_OR_ANDROID') {
                return getFileTypeWithExtension(AppleOrAndroidTimelineJsonImportFileType);
              } else {
                return getFileTypeWithExtension(GoogleTimelineJsonImportFileType);
              }
            case 'EXCEL':
              return getFileTypeWithExtension(ExcelImportFileType);
          }
        })
        .join(',');
      this.maxFileSize = this.selectedSourceType.maxFileSize;
    }
  }

  ngOnInit(): void {
    this.importConfig.uploadSourceTypes.find(_sourceType => {
      if (_sourceType.value === this.selectedSourceType.type) {
        this.selectedSourceTypeTranslateKey = _sourceType.translateKey;
      }
    });

    if (isNil(this.selectedSourceType.type)) {
      this.importSampleUrl = null;
    } else {
      this.importSampleUrl = isFunction(this.selectedSourceType.importSampleUrl) ? this.selectedSourceType.importSampleUrl() : null;
    }
  }

  downloadSampleXLSFile(): void {
    if (this.downloadFileAnchor.length === 1) {
      this.downloadFileAnchor.first.nativeElement.click();
    }
  }

  /**
   * Egy ellenőrző függvény ami akkor ad vissza igaz értéket, ha a kiválasztott fájl típusa ZIP.
   * @param file {File}
   */
  isZIP(file: File): boolean {
    return file.type.toLocaleLowerCase().indexOf('zip') > -1;
  }
}
