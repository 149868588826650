<ng-template #customTemplateRef>
  <rr-status-panel *ngIf="status?.total > 0" [status]="status"></rr-status-panel>
</ng-template>
<rr-grid
  #rrGrid
  [hasFilter]="false"
  [setIconFn]="setIconFn"
  [hasIconColumn]="true"
  [hasActionsColumn]="false"
  [multiSelection]="false"
  [hasCheckboxColumn]="false"
  [autoOpenEditRoute]="false"
  [databaseCallback]="databaseCallback"
  [customAreaTemplateRef]="customTemplateRef"
  [columnDefinitions]="displayedColumns"
  [hasRightClickContextDefaultGridOptions]="false"
  [titleText]="['PERIODICAL.LIST.TITLE', '']"
  [headerGroupActions]="[]"
  [headerOtherActions]="headerOtherActions"
  [cellsTemplateRef]="[CheckerStatusCellTpl, AIStatusCellTpl]"
  [hasNotEntitiesDataLabelLinkCallback]="hasNotEntitiesDataLabelLinkCallbackFn"
></rr-grid>

<ng-template #CheckerStatusCellTpl let-rowData>
  <ng-container *ngIf="rowData?.checker_result; else cellCheckerTpl">
    <button
      color="warn"
      mat-icon-button
      type="button"
      matTooltip="{{ 'PERIODICAL.STATUS.CHECKER.TOOLTIP.UNSUCCESSFUL' | transloco }}"
      (click)="openCheckerResultDialog(rowData.checker_result)"
    >
      <mat-icon svgIcon="alert-box"></mat-icon>
    </button>
  </ng-container>
  <ng-template #cellCheckerTpl>
    <mat-icon class="check-success" matTooltip="{{ 'PERIODICAL.STATUS.CHECKER.TOOLTIP.SUCCESS' | transloco }}" svgIcon="check"></mat-icon>
  </ng-template>
</ng-template>

<ng-template #AIStatusCellTpl let-rowData>
  <ng-container
    *ngIf="
      [PeriodicalRecommendationStatusEnum.FINISHED_OK, PeriodicalRecommendationStatusEnum.FINISHED_WITH_CORRECTION].includes(
        rowData.status
      );
      else cellAITpl
    "
  >
    <button color="warn" mat-icon-button type="button" (click)="openAIResultDialog(rowData.route_correction_result)">
      <mat-icon class="check-success" svgIcon="check" matTooltip="{{ 'PERIODICAL.STATUS.AI.TOOLTIP.SUCCESS' | transloco }}"> </mat-icon>
    </button>
  </ng-container>
  <ng-template #cellAITpl>
    <ng-container *ngIf="rowData?.route_correction_result; else cellAITplWithoutResult">
      <button
        color="warn"
        mat-icon-button
        type="button"
        matTooltip="{{ 'PERIODICAL.STATUS.AI.TOOLTIP.UNSUCCESSFUL' | transloco }}"
        (click)="openAIResultDialog(rowData.route_correction_result)"
      >
        <mat-icon class="ai-error" svgIcon="alert-box"></mat-icon>
      </button>
    </ng-container>
    <ng-template #cellAITplWithoutResult>
      <div class="d-flex justify-content-center" style="line-height: 40px">
        <mat-icon class="ai-error" svgIcon="alert-box" matTooltip="{{ 'PERIODICAL.STATUS.AI.TOOLTIP.UNSUCCESSFUL' | transloco }}">
        </mat-icon>
      </div>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #dialogButtonsTpl>
  <button mat-raised-button color="primary" class="submit-button" (click)="onClickCloseCheckErrorResultsDialog()">
    <span>{{ 'COMMON.ACTION.CLOSE' | transloco }}</span>
  </button>
</ng-template>

<ng-template #dialogContentTpl>
  <mat-accordion [multi]="true">
    <mat-expansion-panel
      *ngFor="let check of selectedRowCheckedList"
      [expanded]="check.status === reportPrintStatusEnum.WARNING || check.status === reportPrintStatusEnum.ERROR"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container [ngSwitch]="check.status">
            <ng-container *ngSwitchCase="reportPrintStatusEnum.WARNING">
              <span class="status-chip check-warning">{{ 'REPORT_PRINT.PANEL.STATUS.WARNING' | transloco }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="reportPrintStatusEnum.ERROR">
              <span class="status-chip check-error">{{ 'REPORT_PRINT.PANEL.STATUS.ERROR' | transloco }}</span>
            </ng-container>
          </ng-container>
          <span class="title">{{ check.title }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p>
          <b>{{ 'REPORT_PRINT.PANEL.CONTENT.RESULT_LABEL' | transloco }}</b
          >&nbsp;<span [innerHTML]="check.result | textPlaceholderHtmlTagReplacer"></span>
        </p>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
