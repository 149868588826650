import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BatchRequestService, ENTITY_SERVICE_TOKEN, HttpListResponseModel } from '@roadrecord/utils';
import { GridColumnModel, GridComponent, gridDatabaseCallback, GridHeaderOtherAction } from '@roadrecord/grid';
import { TranslocoService } from '@ngneat/transloco';
import { listColumnConfig } from '../model/periodical-list-column.config';
import { setIconFn } from './set-icon.function';
import { RecommendationPeriodicalStatusModel } from '../model/recommendation-settings.model';
import { PeriodicalService } from './periodical.service';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogOptionsInterface, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import {
  baseTable,
  getRowTpl,
  isPrivatePeriodContextFn,
  RecommendationFinishStatusModel,
  RecommendationPayOfTypeEnum,
  RecommendationStatusEnum,
} from '@roadrecord/recommendation';
import { Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { MONTH_ACTIVITIES_PAGE_PATH, RECOMMENDATION_SETTINGS_ROUTE_PATH } from '@roadrecord/common/common';
import { DialogComponent } from '@roadrecord/dialog';
import { ReportPrintCheckDataModel, ReportPrintStatusEnum } from '@roadrecord/report-print/common';
import { PeriodicalRecommendationStatusEnum } from '../model/periodical-recommendation-status.enum';
import { Router } from '@angular/router';

export function hasNotEntitiesDataLabelLinkCallbackFn(cmp: PeriodicalListComponent) {
  return () => cmp.navigateToSetting();
}

@UntilDestroy()
@Component({
  selector: 'rr-periodical-list',
  templateUrl: './periodical-list.component.html',
  styleUrls: ['./periodical-list.component.scss'],
  providers: [PeriodicalService, { provide: ENTITY_SERVICE_TOKEN, useExisting: PeriodicalService }],
})
export class PeriodicalListComponent implements OnDestroy {
  displayedColumns: GridColumnModel<any>[] = listColumnConfig(this);
  databaseCallback: gridDatabaseCallback;
  @ViewChild('rrGrid', { static: true })
  rrGrid: GridComponent;
  setIconFn = setIconFn(this);
  status: RecommendationPeriodicalStatusModel;
  hasNotEntitiesDataLabelLinkCallbackFn = hasNotEntitiesDataLabelLinkCallbackFn(this);

  readonly headerOtherActions: GridHeaderOtherAction<any>[] = [
    {
      type: 'ITEM',
      label: { translate: 'COMMON.ACTION.REFRESH' },
      icon: 'refresh',
      action: () => this.rrGrid.refresh(),
    },
    {
      type: 'ITEM',
      icon: 'undo',
      label: { translate: 'APP_LAYOUT.MENU.ITEM.RECOMMENDATION_SETTINGS.TITLE' },
      route: [`/${MONTH_ACTIVITIES_PAGE_PATH}/${RECOMMENDATION_SETTINGS_ROUTE_PATH}`],
      relativeRoute: false,
      disabled: () => this.rrGrid.isLoadingResults,
    },
  ];

  readonly PeriodicalRecommendationStatusEnum = PeriodicalRecommendationStatusEnum;
  selectedRowCheckedList: ReportPrintCheckDataModel[] = [];
  reportPrintStatusEnum = ReportPrintStatusEnum;
  @ViewChild('dialogButtonsTpl') dialogButtonsTpl: TemplateRef<any>;
  @ViewChild('dialogContentTpl') dialogContentTpl: TemplateRef<any>;
  dialogCheckerErrorResultRef: MatDialogRef<DialogComponent, any>;

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    readonly translocoService: TranslocoService,
    private periodicalService: PeriodicalService,
    private batchRequestService: BatchRequestService,
    private messageDialogService: MessageDialogService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
  ) {
    this.assignDatabaseCallback();
  }

  openAIResultDialog(rowData: RecommendationFinishStatusModel): void {
    const dialogOptions: MessageDialogOptionsInterface = {
      id: null,
      text: undefined,
      htmlMode: true,
      translateText: false,
    };
    const matDialogOptions: MatDialogConfig = { width: '800px', maxHeight: '95vh' };

    const isPrivatePeriodContext = isPrivatePeriodContextFn(
      this.store.selectSnapshot(this.periodContextStateSelectorsService.context),
      this.periodContextStateSelectorsService
    );

    let title: string;
    let table: string;
    let content: string;

    switch (rowData.status) {
      case RecommendationStatusEnum.OK:
        dialogOptions.type = MessageDialogTypeEnum.INFORMATION;
        if (rowData.pay_of_type === RecommendationPayOfTypeEnum.DISTANCE) {
          title = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_DISTANCE.TITLE';
          content = '';
          table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
            '%%ROWS%%',
            rowData.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
          );
          matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
        } else if (rowData.pay_of_type === RecommendationPayOfTypeEnum.COST) {
          title = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_COST.TITLE';
          content = 'RECOMMENDATION.FINISH_RESPONSE.OK.PAY_OF_COST.CONTENT';
          table = '';
        }
        dialogOptions.title = title;
        content = content.length > 0 ? this.translocoService.translate(content, rowData.content) : '';
        dialogOptions.text = `<p>${content}</p>${table}`;
        break;
      case RecommendationStatusEnum.WARNING:
        dialogOptions.type = MessageDialogTypeEnum.INFORMATION;
        if (isPrivatePeriodContext) {
          title = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.PRIVATE_PERIOD_CONTEXT.TITLE';
          content = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.PRIVATE_PERIOD_CONTEXT.CONTENT';
          table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
            '%%ROWS%%',
            rowData.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
          );
          matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
        } else {
          title = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.COMPANY_PERIOD_CONTEXT.TITLE';
          content = 'RECOMMENDATION.FINISH_RESPONSE.WARNING.COMPANY_PERIOD_CONTEXT.CONTENT';
          table = baseTable(this.translocoService, !isPrivatePeriodContext).replace(
            '%%ROWS%%',
            rowData.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext)).join('')
          );
          matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
        }
        dialogOptions.title = title;
        dialogOptions.text = `<p>${this.translocoService.translate(content, rowData.content)}</p>${table}`;
        break;
      case RecommendationStatusEnum.ERROR:
        dialogOptions.type = MessageDialogTypeEnum.WARNING;
        if (isPrivatePeriodContext) {
          title = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.PRIVATE_PERIOD_CONTEXT.TITLE';
          content = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.PRIVATE_PERIOD_CONTEXT.CONTENT';
          table = baseTable(this.translocoService, !isPrivatePeriodContext, false).replace(
            '%%ROWS%%',
            rowData.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext, false)).join('')
          );
          matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
        } else {
          title = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.COMPANY_PERIOD_CONTEXT.TITLE';
          content = 'RECOMMENDATION.FINISH_RESPONSE.ERROR.COMPANY_PERIOD_CONTEXT.CONTENT';
          const is_fueling_range_correctable = rowData.table.some(row => row.hasOwnProperty('is_fueling_range_correctable'));

          table = baseTable(this.translocoService, !isPrivatePeriodContext, false, is_fueling_range_correctable).replace(
            '%%ROWS%%',
            rowData.table.map(row => getRowTpl(this.translocoService, row, !isPrivatePeriodContext, false)).join('')
          );
          matDialogOptions.panelClass = ['with-table-content', 'has-scroll-table', 'responsive'];
        }
        dialogOptions.title = title;
        dialogOptions.text = `<p>${this.translocoService.translate(content, rowData.content)}
            </p>${table}<br/>${this.translocoService.translate('RECOMMENDATION.FINISH_RESPONSE.ERROR.COMMON.WARNING_TEXT')}`;
        break;
    }

    if (dialogOptions.type !== undefined) {
      this.messageDialogService.open(dialogOptions, matDialogOptions);
    }
  }

  openCheckerResultDialog(checkData: ReportPrintCheckDataModel[]) {
    this.selectedRowCheckedList = checkData.filter(item => item.status === ReportPrintStatusEnum.ERROR);
    this.dialogCheckerErrorResultRef = this.dialog.open(DialogComponent, {
      maxWidth: 1024,
      panelClass: ['rr-dialog'],
      data: {
        maximizeMode: false,
        hasActionLeftButtons: false,
        title: this.translocoService.translate('REPORT.DIALOG.TITLE'),
        buttonsTpl: this.dialogButtonsTpl,
        contentTpl: this.dialogContentTpl,
      },
    });
  }

  onClickCloseCheckErrorResultsDialog() {
    this.dialogCheckerErrorResultRef.close();
    this.selectedRowCheckedList = [];
  }

  private assignDatabaseCallback(): void {
    this.databaseCallback = (database, sort, page, filter) => {
      this.batchRequestService.start();
      this.batchRequestService.add(this.periodicalService.getAllBatch(sort, page, filter));
      this.batchRequestService.add(this.periodicalService.getStatusBatch());

      return this.batchRequestService.end().pipe(
        untilDestroyed(this),
        map((values: [{ body: HttpListResponseModel<any> }, { body: RecommendationPeriodicalStatusModel }]) => {
          this.status = values[1].body;
          return values[0].body;
        })
      );
    };
  }
  ngOnDestroy(): void {}

  navigateToSetting(): void {
    this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${RECOMMENDATION_SETTINGS_ROUTE_PATH}`]);
  }
}
