import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RecommendationPeriodicalStatusModel } from '../../model/recommendation-settings.model';

@Component({
  selector: 'rr-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPanelComponent {
  @Input() status: RecommendationPeriodicalStatusModel;

  constructor() {}
}
