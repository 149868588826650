import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { fadeInEnter } from '@roadrecord/animations';
import { baseNotAccessUrl } from '@roadrecord/app-layout/common';
import {
  BASIC_DATA_PAGE_PATH,
  deepEqual,
  MONTH_ACTIVITIES_PAGE_PATH,
  REPORT_PRINT_PATH,
  VEHICLE_ROUTE_PATH,
  WINDOW,
} from '@roadrecord/common/common';
import { MaybeHandleHttpError } from '@roadrecord/utils';
import { saveAs } from 'file-saver';
import printJS from 'print-js';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, take } from 'rxjs/operators';
import { ReportPrintService } from '../report-print.service';
import { ReportPrintChangeFilterAction } from '../state/action/report-print-change-filter.action';
import { ReportPrintClearStateAction } from '../state/action/report-print-clear-state.action';
import { ReportPrintCheckDataModel } from '../state/model/report-print-check-data.model';
import { ReportPrintStatusEnum } from '../state/model/report-print-status.enum';
import { ReportPrintState } from '../state/report-print.state';
import { DocumentDataCheckerActionsEnum, DocumentDataCheckerActionUiConfig } from '@roadrecord/document-data-checker-action/model';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { handleDocumentDataCheckerAction } from '@roadrecord/document-data-checker-action/helper';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { ReportPrintCheckListFilterPipe } from '../pipe/report-print-check-list-filter.pipe';
import { ResultPanelComponent } from '../result-panel/result-panel/result-panel.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT_DATA_CHECKER_ACTION_CONFIG, generateDocumentDataCheckerConfig } from '@roadrecord/document-data-checker-action/ui';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { extraButtonsConfig } from '../config/extra-buttons.config';
import { ExtraButtonConfig } from '@roadrecord/report-print/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ReportPrintFastCheckAction } from '../state/action/report-print-fast-check.action';
import { ReportPrintFastCheckSuccessAction } from '../state/action/report-print-fast-check-success.action';
import { isNil } from '@roadrecord/type-guard';

@UntilDestroy()
@Component({
  selector: 'rr-report-print',
  templateUrl: './report-print.component.html',
  styleUrls: ['./report-print.component.scss'],
  providers: [ReportPrintService],
  animations: [fadeInEnter],
})
export class ReportPrintComponent implements OnInit, OnDestroy {
  ReportPrintStatusEnum = ReportPrintStatusEnum;
  @Select(ReportPrintState.countAll)
  readonly countAll$: Observable<number>;
  @Select(ReportPrintState.countAllWithoutError)
  readonly countAllWithoutError$: Observable<number>;
  @Select(ReportPrintState.countOK)
  readonly countOK$: Observable<number>;
  @Select(ReportPrintState.countWarning)
  readonly countWarning$: Observable<number>;
  @Select(ReportPrintState.countNotChecked)
  readonly countNotChecked$: Observable<number>;
  @Select(ReportPrintState.countError)
  readonly countError$: Observable<number>;

  @Select(ReportPrintState.currentFilter)
  readonly currentFilter$: Observable<ReportPrintStatusEnum | 'ALL'>;
  @Select(ReportPrintState.counter)
  readonly counter$: Observable<number>;
  @Select(ReportPrintState.currentStepCounter)
  readonly currentStepCounter$: Observable<number>;
  @Select(ReportPrintState.checkList)
  readonly checkList$: Observable<ReportPrintCheckDataModel[]>;

  run: boolean;

  linkFileName: string = null;
  private timerSubscription: Subscription;
  visiblePrintButton = false;
  runPdfGeneration = false;

  @HostBinding('class.pdf-view')
  pdfViewClass = false;

  pdfSrc: any;
  private pdfSrcBlob: Blob;

  hasError = false;
  private actionsConfig: DocumentDataCheckerActionUiConfig[];

  @ViewChild(ResultPanelComponent) resultPanelComponent: ResultPanelComponent;
  /**
   * azert kell mert, stream-eken van untilDestroyed, viszont meg igy is elofordul hogy
   * onDestroy futasa alatt fut egy stream...
   */
  private runOnDestroy = false;
  readonly extraButtonsConfig = extraButtonsConfig;
  /**
   * Akkor hasznaljuk, amikor mar latja a pdf-et de valami tavoli lassabb muveletet inditunk, pl: Freshbook feltoltes
   */
  loadingInPdfView = false;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private actions: Actions,
    private matSnackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private matDialog: MatDialog,
    private reportService: ReportPrintService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    private loadableService: LoadableService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private messageDialogService: MessageDialogService,
    private gtmService: GoogleTagManagerService
  ) {
    this.actionsConfig = generateDocumentDataCheckerConfig(DOCUMENT_DATA_CHECKER_ACTION_CONFIG);
    //this.checkPeriodContextChange();
  }

  private watchRunStateChangeAndFixChangeDetector(): void {
    this.store
      .select(ReportPrintState.run)
      .pipe(untilDestroyed(this))
      .subscribe(run => {
        if (this.runOnDestroy === false) {
          if (this.run === true && run === false) {
            this.subscribeOneReportFastCheckAction();
          }
          this.run = run;
          this.cdr.detectChanges();
        }
      });
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        skip(1),
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const skipFinalizationOfRouteNavigate = this.route.snapshot.queryParamMap.get('skip-finalization-of-route-navigate');
        if (isNil(skipFinalizationOfRouteNavigate)) {
          this.router.navigate([baseNotAccessUrl]);
        }
      });
  }

  ngOnInit(): void {
    this.watchRunStateChangeAndFixChangeDetector();

    // ha mashonnan jovunk gyorsan ahol eppen meg nyitva volt egy snackbar akkor be kell zarni
    this.matSnackBar.dismiss();
    //this.subscribeOneReportFastCheckAction();
    this.store.dispatch(new ReportPrintFastCheckAction());
  }

  private subscribeOneReportFastCheckAction() {
    this.actions
      .pipe(ofActionSuccessful(ReportPrintFastCheckSuccessAction), untilDestroyed(this), take(1))
      .subscribe((reportAction: ReportPrintFastCheckSuccessAction) => {
        if (reportAction.state.auto_correction) {
          this.matSnackBar.open(this.translocoService.translate('REPORT_PRINT.SNACKBAR.AUTO_CORRECTION'));
        }
        if (this.store.selectSnapshot(ReportPrintState.countError) > 0) {
          this.store.dispatch(new ReportPrintChangeFilterAction(ReportPrintStatusEnum.ERROR));
          this.sendGoogleAnalyticsEvent();
        } else if (this.store.selectSnapshot(ReportPrintState.countWarning) > 0) {
          this.store.dispatch(new ReportPrintChangeFilterAction(ReportPrintStatusEnum.WARNING));
          this.visiblePrintButton = true;
          this.sendGoogleAnalyticsEvent();
        } else {
          this.visiblePrintButton = true;
          this.onPrintPdf(false);
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * @param force csak mw-ben hasznaljuk
   */
  onPrintPdf(force: boolean): void {
    const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);

    this.reportService.getVehicleDataCheck(periodContext.vehicle.id, periodContext.year, periodContext.month).subscribe(
      response => {
        if (response['printing_allowed'] === true) {
          this.runPdfGeneration = true;
          this.reportService.getDocument(force, periodContext.vehicle.id, periodContext.year, periodContext.month).subscribe(
            httpResponse => {
              this.document.body.classList.add('no-scroll');
              this.pdfSrcBlob = (httpResponse.body as any) as Blob;
              this.pdfSrc = URL.createObjectURL(this.pdfSrcBlob);
              this.linkFileName = httpResponse.headers.get('content-disposition').split('filename=')[1];

              this.pdfViewClass = true;
              this.cdr.markForCheck();
            },
            error => {
              MaybeHandleHttpError.maybeHandleHttpError(error);
              this.hasError = true;
              this.cdr.detectChanges();
            }
          );
        } else {
          this.openErrorResultsDialog();
        }
      },
      error => {
        MaybeHandleHttpError.maybeHandleHttpError(error);
        this.hasError = true;
        this.cdr.detectChanges();
      }
    );
  }

  private openErrorResultsDialog() {
    this.messageDialogService
      .openWarning({
        id: null,
        confirmLabel: 'REPORT_PRINT.NOT_VALID_VEHICLE.POPUP.BUTTONS.NAVIGATE_TO_VEHICLE',
        text: this.translocoService.translate('REPORT_PRINT.NOT_VALID_VEHICLE.POPUP.MESSAGE'),
      })
      .afterClosed()
      .subscribe(() => this.onNavigateToVehicle());
  }

  onNavigateToVehicle() {
    const periodContext = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);

    this.router.navigate([`/${BASIC_DATA_PAGE_PATH}/${VEHICLE_ROUTE_PATH}/${periodContext.vehicle.id}/`], {
      queryParams: { back_url: `/${MONTH_ACTIVITIES_PAGE_PATH}/${REPORT_PRINT_PATH}` },
    });
  }
  ngOnDestroy(): void {
    this.runOnDestroy = true;
    this.store.dispatch(new ReportPrintClearStateAction());
    this.unsubscribeTimerSubscription();
    if (this.document.body.classList.contains('no-scroll')) {
      this.document.body.classList.remove('no-scroll');
    }
  }

  private unsubscribeTimerSubscription() {
    if (this.timerSubscription !== undefined && !this.timerSubscription.closed) {
      this.timerSubscription.unsubscribe();
    }
  }

  onClickPrint(): void {
    printJS({
      // clone blob
      printable: URL.createObjectURL(this.pdfSrcBlob),
      type: 'pdf',
      showModal: true,
      modalMessage: this.translocoService.translate('REPORT_PRINT.PDF.PRINTING_MODAL_MESSAGE'),
      documentTitle: this.linkFileName,
      onError: () => {
        this.onClickPdfDownload();
        this.messageDialogService.openWarning({
          id: null,
          text: 'REPORT_PRINT.PDF.ERROR_PRINT',
        });
      },
    });
  }

  onClickPdfDownload(): void {
    saveAs(this.pdfSrcBlob, this.linkFileName);
  }

  onClickExcelOrCsvDownload(isExcel: boolean): void {
    this.reportService.getExcelOrCsv(isExcel).subscribe(
      httpResponse => {
        const srcBlob = (httpResponse.body as any) as Blob;
        const linkFileName = httpResponse.headers.get('content-disposition').split('filename=')[1];
        saveAs(srcBlob, linkFileName);
      },
      error => {
        MaybeHandleHttpError.maybeHandleHttpError(error);
        this.hasError = true;
        this.cdr.detectChanges();
      }
    );
  }

  onClickAction(actions: { action: DocumentDataCheckerActionsEnum }[]) {
    handleDocumentDataCheckerAction(
      actions,
      this.actionsConfig,
      this.router,
      this.store,
      this.loadableService,
      this.injector,
      this.matSnackBar,
      this.translocoService
    );
  }

  onClickOpenErrorWindow() {
    const countError = this.store.selectSnapshot(ReportPrintState.countError);
    const countWarning = this.store.selectSnapshot(ReportPrintState.countWarning);

    const currentFilter$ = this.store.select(ReportPrintState.currentFilter).pipe(
      map(state => {
        if (
          [ReportPrintStatusEnum.ERROR, ReportPrintStatusEnum.WARNING].indexOf(
            this.store.selectSnapshot(ReportPrintState.currentFilter) as any
          ) > -1
        ) {
          return state;
        }
        return countError > 0 ? ReportPrintStatusEnum.ERROR : ReportPrintStatusEnum.WARNING;
      })
    );
    const list$ = currentFilter$.pipe(
      map(state => new ReportPrintCheckListFilterPipe().transform(this.store.selectSnapshot(ReportPrintState.checkList), state))
    );
  }

  onClickExtraButton(config: ExtraButtonConfig) {
    this.loadingInPdfView = true;
    config.click(this.injector).subscribe(programmaticallyClosed => {
      this.loadingInPdfView = false;
      if (programmaticallyClosed) {
        // gomb ujra megnyomasa
        this.onClickExtraButton(config);
      }
      this.cdr.detectChanges();
    });
  }

  private sendGoogleAnalyticsEvent(): void {
    this.gtmService.pushTag({
      event: 'print_results_updated',
      count_all: this.store.selectSnapshot(ReportPrintState.countAll),
      count_error: this.store.selectSnapshot(ReportPrintState.countError),
      count_successful: this.store.selectSnapshot(ReportPrintState.countOK),
      count_warning: this.store.selectSnapshot(ReportPrintState.countWarning),
    });
  }
}
