import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Remote, wrap } from '@roadrecord/comlink';
import { setThrowOverrideHandler } from '@roadrecord/worker/shared';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import {
  UniversalAssociatedHeadersModel,
  UniversalImportConfigModel,
  UniversalImportControllerType,
  UniversalImportWorker,
} from '@roadrecord/worker/universal-import';
import { BehaviorSubject } from 'rxjs';

// ez kell ide?!?
setThrowOverrideHandler();

@Injectable()
export abstract class UniversalImportWorkerBridgeService implements OnDestroy {
  private workerProxy: Remote<UniversalImportWorker>;
  private rawWorker: Worker;
  private _live$ = new BehaviorSubject<boolean>(false);
  get live$() {
    return this._live$.asObservable();
  }

  constructor(
    private ngZone: NgZone,
    private store: Store,
    @Inject('') private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    @Inject('') private isE2E: boolean,
    @Inject('') private checkDate = true,
    @Inject('') private _allowedRowCount = 500
  ) {
    /* Promise azert kell, mert DI-ban nem erkezik meg minden idoben a Child osztalyban */
    Promise.resolve().then(() =>
      ngZone.runOutsideAngular(async () => {
        await this.initWorker();
      })
    );
  }

  get allowedRowCount(): number {
    return this._allowedRowCount;
  }

  /**
   * file size in megabyte
   */
  private _allowedSize = 10;

  get allowedSize(): number {
    return this._allowedSize;
  }

  get userFileHeaders() {
    return this.workerProxy.getUserFileHeaders();
  }

  get rowsLength() {
    return this.workerProxy.getRowsLength();
  }

  get isCSV() {
    return this.workerProxy.isCSV();
  }

  get isJSON() {
    return this.workerProxy.isJSON();
  }

  async setMaxFileSize(maxFileSize: number) {
    await this.workerProxy.setMaxFileSize(maxFileSize);
  }

  async setDeterminateTimelineFileType(determinate: boolean) {
    await this.workerProxy.setDeterminateTimelineFileType(determinate);
  }

  get recognizedTimelineFileType() {
    return this.workerProxy.recognizedTimelineFileType;
  }

  setFile(file: File, headerRowIndex: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ngZone.runOutsideAngular(async () => {
        try {
          const result = await this.workerProxy.setFile(file, headerRowIndex);
          this.ngZone.run(() => resolve(result));
        } catch (e) {
          this.ngZone.run(() => reject(e));
        }
      });
    });
  }

  getBaseWorker(): Worker {
    return null;
  }

  async initWorker() {
    if (this.rawWorker !== undefined) {
      return;
    }
    this.rawWorker = this.getBaseWorker();
    const workerProxy = wrap<UniversalImportWorker>(this.rawWorker);

    // @ts-ignore
    this.workerProxy = await new workerProxy(
      this.allowedSize,
      this._allowedRowCount,
      this.store.selectSnapshot(this.periodContextStateSelectorsService.viewDate),
      this.checkDate,
      this.isE2E ? { credentials: 'include' } : {}
    );
    this._live$.next(true);
  }

  ngOnDestroy(): void {
    this._live$.next(false);
    delete this.workerProxy;
    // safe remove, without error :(
    Promise.resolve(null).then(() => {
      this.rawWorker.terminate();
      delete this.rawWorker;
    });
  }

  setAssociateHeaders(associateHeaders: UniversalAssociatedHeadersModel) {
    return this.workerProxy.setAssociatedHeaders(associateHeaders);
  }

  sendData(url: string, token: string, cb: any, source_type: string, config: UniversalImportConfigModel) {
    return this.workerProxy.sendData(url, token, cb, source_type, config);
  }

  async reset() {
    await this.workerProxy.reset();
  }

  async setCurrentYearMonth(value: Date) {
    await this.workerProxy.setCurrentYearMonth(value);
  }

  setControllerType(controllerType: UniversalImportControllerType) {
    return this.workerProxy.setControllerType(controllerType);
  }

  uploadCompressFile(url: string, token: string) {
    return this.workerProxy.uploadCompressedFile(url, token);
  }
}
