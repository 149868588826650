import { ImportFileType, ImportFileTypeEnum } from './types';
import { CSV_FILE_TYPE, EXCEL_FILE_TYPE, JSON_FILE_TYPE } from '@roadrecord/worker/shared';

export const ExcelImportFileType: ImportFileType = {
  name: ImportFileTypeEnum.EXCEL,
  types: EXCEL_FILE_TYPE,
  extensions: ['xlsx'],
  contentType: 'binary',
};

export const CsvImportFileType: ImportFileType = {
  name: ImportFileTypeEnum.CSV,
  types: CSV_FILE_TYPE,
  extensions: ['csv'],
  contentType: 'text',
};

export const GoogleTimelineJsonImportFileType: ImportFileType = {
  name: ImportFileTypeEnum.GOOGLE_TIMELINE_JSON,
  types: JSON_FILE_TYPE,
  extensions: ['json', 'zip'],
  contentType: 'text',
};

export const AppleOrAndroidTimelineJsonImportFileType: ImportFileType = {
  name: ImportFileTypeEnum.GOOGLE_TIMELINE_APPLE_OR_ANDROID,
  types: JSON_FILE_TYPE,
  extensions: ['json'],
  contentType: 'text',
};

export function getFileTypeWithExtension(importFileType: ImportFileType) {
  return `${importFileType.types.join(',')},${importFileType.extensions.map(ext => `.${ext}`).join(',')}`;
}

export function getAllFileTypeWithExtensionToUniversalImport() {
  return `${getFileTypeWithExtension(ExcelImportFileType)},${getFileTypeWithExtension(CsvImportFileType)},${getFileTypeWithExtension(
    GoogleTimelineJsonImportFileType
  )}`;
}
