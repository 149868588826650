import { UniversalAssociatedHeadersModel } from '../../model/universal-associated-headers.model';

export type UniversalImportControllerType = 'EXCEL_OR_CSV' | 'GOOGLE_TIMELINE_JSON' | 'GOOGLE_TIMELINE_APPLE_OR_ANDROID';

export enum UniversalImportControllerTypeEnum {
  EXCEL_OR_CSV = 'EXCEL_OR_CSV',
  GOOGLE_TIMELINE_JSON = 'GOOGLE_TIMELINE_JSON',
  GOOGLE_TIMELINE_APPLE_OR_ANDROID = 'GOOGLE_TIMELINE_APPLE_OR_ANDROID',
}

export enum RecognizedTimelineFileTypeEnum {
  APPLE,
  ANDROID,
}
export interface UniversalImportControllerOptions {
  associatedHeaders: UniversalAssociatedHeadersModel | null;
  periodContextCurrentYearMonth: string;
  periodContextCurrentYearMonthCheckDateFormat: string;
  maxFileSize: number;
}

export enum ImportFileTypeEnum {
  CSV,
  EXCEL,
  GOOGLE_TIMELINE_JSON,
  GOOGLE_TIMELINE_APPLE_OR_ANDROID,
}

export interface ImportFileType {
  name: ImportFileTypeEnum;
  types: string[];
  extensions: string[];
  contentType: 'text' | 'binary';
}
