import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { BatchRequestModel, HandleErrorObject, handleHttpError, RRHttpErrorResponse } from '@roadrecord/utils';
import { FuelingRangeRefreshAction } from '@roadrecord/fueling-range/common';
import { MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { baseTable, getRowTpl } from '@roadrecord/recommendation';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ModifyEventModel } from './details/table/model/modify-event.model';
import { FinalizationOfRouteCorrectionFinishAction } from './details/state/action/finalization-of-route-correction-finish.action';
import { FinalizationOfRouteRouteCorrectionModel } from './model/finalization-of-route-route-correction.model';
import { FinalizationOfRouteSummaryModel } from './model/finalization-of-route-summary.model';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { LoadableService } from '@roadrecord/external-packages/ngx-loadable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PreferencesContentWindowTypeEnum, PreferencesState } from '@roadrecord/preferences-state/common';
import { StartResponse } from './details/state/model/start.response';
import { FinalizationOfRouteModel } from './model/finalization-of-route.model';
import { fuelingRangeOpenWindowAction } from '@roadrecord/fueling-range/open-window-action';
import { environment } from '@roadrecord/environment';

@Injectable()
export class FinalizationOfRouteService {
  protected entityUrl = 'finalization-of-route';
  private httpErrorInterceptor: HttpErrorInterceptor;

  constructor(
    private http: HttpClient,
    private messageDialogService: MessageDialogService,
    private translocoService: TranslocoService,
    private store: Store,
    @Inject(HTTP_INTERCEPTORS) private httpInterceptors: any[],
    private loadableService: LoadableService,
    private injector: Injector,
    private matSnackBar: MatSnackBar
  ) {
    this.httpErrorInterceptor = httpInterceptors.find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor);
  }

  getCalendarData(params?: { 'cancel-editing': 'true' | 'false' }): Observable<any> {
    return this.http.get(`${environment.api2Url}${this.entityUrl}/`, { params });
  }

  get(date: string): Observable<FinalizationOfRouteModel> {
    return this.http.get<FinalizationOfRouteModel>(`${environment.api2Url}${this.entityUrl}/${date}/`).pipe(
      map(model => {
        // row_index alapjan rendezes
        model.routes.sort((a, b) => (a.row_index < b.row_index ? -1 : a.row_index > b.row_index ? 1 : 0));
        return model;
      })
    );
  }

  getBatch(date: string): BatchRequestModel {
    return { method: 'get', relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/` };
  }

  getBatchOperators(): any[] {
    return [
      map<FinalizationOfRouteModel, FinalizationOfRouteModel>(model => {
        // row_index alapjan rendezes
        model.routes.sort((a, b) => (a.row_index < b.row_index ? -1 : a.row_index > b.row_index ? 1 : 0));
        return model;
      }),
    ];
  }

  start(date: string): Observable<StartResponse> {
    return this.http.post<StartResponse>(`${environment.api2Url}${this.entityUrl}/${date}/start/`, undefined);
  }

  checkModified(date: string): Observable<number> {
    return this.http.get(`${environment.api2Url}${this.entityUrl}/${date}/has-changed/`, { responseType: 'text' }).pipe(map(v => +v));
  }

  cancel(date: string): Observable<any> {
    return this.http.post<any>(`${environment.api2Url}${this.entityUrl}/${date}/cancel/`, undefined);
  }

  cancelBatch(date: string): BatchRequestModel {
    return {
      method: 'post',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/cancel/`,
      body: undefined,
    };
  }

  save(date: string): Observable<void> {
    return this.http.post<any>(`${environment.api2Url}${this.entityUrl}/${date}/save/`, undefined);
  }

  saveBatch(date: string): BatchRequestModel {
    return {
      method: 'post',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/save/`,
      body: undefined,
    };
  }

  removeRow(date: string, rowIndex: number): Observable<void> {
    return this.http.delete<void>(`${environment.api2Url}${this.entityUrl}/${date}/${rowIndex}/`);
  }

  removeRowBatch(date: string, rowIndex: number): BatchRequestModel {
    return { method: 'delete', relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/${rowIndex}/` };
  }

  deleteAllRowBatch(date: string): BatchRequestModel {
    return { method: 'delete', relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/` };
  }

  insertEmptyRow(date: string, rowIndex: number): Observable<void> {
    return this.http.post<void>(`${environment.api2Url}${this.entityUrl}/${date}/add/${rowIndex}/`, undefined);
  }

  insertEmptyRowBatch(date: string, rowIndex: number): BatchRequestModel {
    return {
      method: 'post',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/add/${rowIndex}/`,
      body: undefined,
    };
  }

  moveRow(date: string, oldRowIndex: number, newRowIndex: number): Observable<void> {
    return this.http.put<void>(`${environment.api2Url}${this.entityUrl}/${date}/change-order/${oldRowIndex}/${newRowIndex}/`, undefined);
  }

  moveRowBatch(date: string, oldRowIndex: number, newRowIndex: number): BatchRequestModel {
    return {
      method: 'put',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/change-order/${oldRowIndex}/${newRowIndex}/`,
      body: undefined,
    };
  }

  importedRoutesDelete() {
    return this.http.delete(`${environment.api2Url}fixed-destination/import/delete/all/`);
  }

  getSummary(date: string): Observable<FinalizationOfRouteSummaryModel[]> {
    return this.http.get<FinalizationOfRouteSummaryModel[]>(`${environment.api2Url}${this.entityUrl}/${date}/summary/`);
  }

  getSummaryBatch(date: string): BatchRequestModel {
    return { method: 'get', relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/summary/` };
  }

  modifyRow(date: string, rowIndex: number, data: ModifyEventModel): Observable<void> {
    return this.http.put<void>(`${environment.api2Url}${this.entityUrl}/${date}/${rowIndex}/`, data);
  }

  modifyRowBatch(date: string, rowIndex: number, data: ModifyEventModel): BatchRequestModel {
    return {
      method: 'put',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/${rowIndex}/`,
      body: JSON.stringify(data),
    };
  }

  startCorrection() {
    this.httpErrorInterceptor.addDisableErrorCode(400);
    this.http
      .get<FinalizationOfRouteRouteCorrectionModel>(`${environment.api2Url}${this.entityUrl}/route-correction/`)
      .pipe(finalize(() => this.httpErrorInterceptor.removeDisableErrorCode(400)))
      .subscribe(
        correction =>
          this.messageDialogService
            .open(
              {
                id: null,
                type: MessageDialogTypeEnum.INFORMATION,
                title: 'FINALIZATION_OF_ROUTE.CORRECTION.RESPONSE_MESSAGE_DIALOG.TITLE',
                translateText: false,
                htmlMode: true,
                text: `<p>${this.translocoService.translate('FINALIZATION_OF_ROUTE.CORRECTION.RESPONSE_MESSAGE_DIALOG.CONTENT', {
                  ...correction.content,
                })}</p>${baseTable(
                  this.translocoService,
                  true,
                  true,
                  false,
                  'FINALIZATION_OF_ROUTE.CORRECTION.RESPONSE_MESSAGE_DIALOG'
                ).replace(
                  '%%ROWS%%',
                  correction.table
                    .map(row =>
                      getRowTpl(this.translocoService, row, true, true, 'FINALIZATION_OF_ROUTE.CORRECTION.RESPONSE_MESSAGE_DIALOG')
                    )
                    .join('')
                )}`,
              },
              { panelClass: ['with-table-content', 'has-scroll-table', 'responsive'] }
            )
            .afterClosed()
            .subscribe(() => this.store.dispatch([new FinalizationOfRouteCorrectionFinishAction(), new FuelingRangeRefreshAction()])),
        error => {
          handleHttpError(
            error,
            undefined,
            this.translocoService,
            this.messageDialogService,
            undefined,
            (__error: RRHttpErrorResponse, translocoService: TranslocoService) => {
              // ha nincs nyitva csak akkor rakjuk ki a gombot
              const fuelingRangeWindowConfig = this.store.selectSnapshot(PreferencesState.hasOpenedContentWindow)(
                PreferencesContentWindowTypeEnum.FUELING_RANGE
              )
                ? {}
                : {
                    enableCancel: true,
                    cancelLabel: 'FINALIZATION_OF_ROUTE.CALENDAR.HEADER.TOP_RIGHT_MENU.FUELING_RANGE.LABEL',
                  };
              HandleErrorObject.handleErrorObject(error, undefined, fuelingRangeWindowConfig, false).subscribe(
                (userChoice: { result: boolean }) => {
                  if (userChoice.result === false) {
                    // azert a false-t kell figyelni, mert cancel gombra dobtuk ra a feature-t
                    fuelingRangeOpenWindowAction(
                      this.store,
                      this.loadableService,
                      this.injector,
                      this.translocoService as any,
                      this.matSnackBar
                    );
                  }
                }
              );
            }
          );
        }
      );
  }

  setDelegationTime(date: string, start: string, end: string): Observable<void> {
    return this.http.put<void>(`${environment.api2Url}${this.entityUrl}/${date}/delegation-time/`, { start, end });
  }

  getCalendarList() {
    return this.http.get<{ data: { days: any; monthly_summary: any }; options: any; meta: { monthly_summary: any } }>(
      `${environment.api2Url}${this.entityUrl}/`,
      { params: { view_type: 'list' } }
    );
  }

  /**
   * Repülő utak kizárása az útnyilvántartásból
   * @param date {string}
   * @param rowIndex {number}
   */
  flightTripBatch(date: string, rowIndex: number): BatchRequestModel {
    return {
      method: 'put',
      relative_url: `/${environment.baseApi2Endpoint}/${this.entityUrl}/${date}/${rowIndex}/`,
      body: JSON.stringify({ is_flight: true }),
    };
  }
}
