import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { isFunction, isNil } from '@roadrecord/type-guard';
import { FormControl, Validators } from '@angular/forms';
import { UNIVERSAL_IMPORT_CONFIG, UniversalImportButtonConfig, UniversalImportConfig } from '../../../model/universal-import-config';
import { fadeIn2XFastEnter, fadeOut2XFastLeave } from '@roadrecord/animations';
import { DOCUMENT } from '@angular/common';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import { TranslocoService } from '@ngneat/transloco';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { UniversalImportControllerTypeEnum } from '../../../../../../../worker/universal-import/src/lib/import-controller/base/types';

const DEFAULT_DURATION = 300;

@UntilDestroy()
@Component({
  selector: 'rr-select-source',
  templateUrl: './select-source.component.html',
  styleUrls: ['./select-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeIn2XFastEnter,
    fadeOut2XFastLeave,
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, overflow: AUTO_STYLE })),
      state('true', style({ height: '160px', overflow: 'hidden' })),
      //transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class SelectSourceComponent implements OnInit, AfterViewInit {
  collapsed = true;
  hasCollapsed = false;
  @Output() changeSourceType = new EventEmitter<UniversalImportButtonConfig<any>>();
  @Output() nextStep = new EventEmitter();
  @ViewChildren('downloadFileAnchor') private downloadFileAnchor: QueryList<ElementRef<HTMLAnchorElement>>;
  @ViewChildren('matCardContent') private matCardContent: QueryList<ElementRef<any>>;

  selectedSourceTypeTranslateKey: string;
  selectedSourceType: UniversalImportButtonConfig<any> | null = null;
  importSampleUrl: string | null = null;

  sourceTypeControl = new FormControl(null, Validators.required);

  readonly fileUploadTranslatePrefix = 'FILE_UPLOAD.';
  readonly isHuAppType = environment.appType === AppTypeEnum.HU;

  linkUrl1: string | null = null;
  linkUrl2: string | null = null;

  linkButtonText1: string | null = null;
  linkButtonText2: string | null = null;

  constructor(
    @Inject(UNIVERSAL_IMPORT_CONFIG) readonly importConfig: UniversalImportConfig<any>,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.initDefaultSelectedButton();
  }

  private translateLinkUrls(): void {
    const excludedValues = ['undefined', 'GYIKURL'];
    const basePath = 'FINALIZATION_OF_ROUTE.IMPORT.UPLOAD_STEPPER.SOURCE_TYPE.TYPES.';
    const rawLinkUrl1 = this.translocoService.translate(`${basePath}${this.selectedSourceTypeTranslateKey}.GYIKURL`);
    const rawLinkUrl2 = this.translocoService.translate(`${basePath}${this.selectedSourceTypeTranslateKey}.GYIKURL_1`);

    this.linkUrl1 = rawLinkUrl1 && excludedValues.every(value => !rawLinkUrl1.includes(value)) ? rawLinkUrl1 : null;
    this.linkUrl2 = rawLinkUrl2 && excludedValues.every(value => !rawLinkUrl2.includes(value)) ? rawLinkUrl2 : null;

    const lintButtonText = this.translocoService.translate(
      `UNIVERSAL_IMPORT.UPLOAD_STEPPER.${this.fileUploadTranslatePrefix}GYIK_BUTTON_LABEL`
    );
    const timelineMobile = this.selectedSourceTypeTranslateKey === UniversalImportControllerTypeEnum.GOOGLE_TIMELINE_APPLE_OR_ANDROID;
    this.linkButtonText1 = !isNil(this.linkUrl1) && timelineMobile ? `${lintButtonText} (iOS)` : lintButtonText;
    this.linkButtonText2 = !isNil(this.linkUrl2) && timelineMobile ? `${lintButtonText} (Android)` : lintButtonText;
  }

  private initDefaultSelectedButton() {
    const skipDefaultSelected = this.route.snapshot.queryParamMap.get('skip-default-selected');
    if (isNil(skipDefaultSelected) || skipDefaultSelected === 'false') {
      const foundDefaultSelectButton = this.importConfig.importButtons.find(item => item.defaultSelected === true);
      if (!isNil(foundDefaultSelectButton) && !this.isHuAppType) {
        this.onClickSourceType(foundDefaultSelectButton.config);
      }
    }
  }

  onClickSourceType(config: UniversalImportButtonConfig<any>) {
    const sourceType = config.type;
    this.sourceTypeControl.patchValue(config);
    this.selectedSourceType = config;
    // szoveg panel megjelenitese ha kell
    delete this.selectedSourceTypeTranslateKey;

    this.importConfig.uploadSourceTypes.find(_sourceType => {
      if (_sourceType.value === sourceType) {
        this.selectedSourceTypeTranslateKey = _sourceType.translateKey;
        this.translateLinkUrls();
        return true;
      }
      return false;
    });

    this.collapsed = true;
    this.hasCollapsed = false;

    if (isNil(sourceType)) {
      this.importSampleUrl = null;
    } else {
      this.importSampleUrl = isFunction(config.importSampleUrl) ? config.importSampleUrl() : null;
    }

    this.changeSourceType.emit(config);

    // scroll top(kisebb felbontason igy felugrik a segitseghez)
    const item = this.document.querySelector('.is-first-step > .mat-card-content');
    if (!isNil(item)) {
      item.scrollTop = 0;
    }

    this.cdr.markForCheck();
    this.ngZone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => this.checkAndSetHasCollapsed());
  }

  downloadSampleXLSFile(): void {
    if (this.downloadFileAnchor.length === 1) {
      this.downloadFileAnchor.first.nativeElement.click();
    }
  }

  onClickNextStep() {
    if (!isNil(this.selectedSourceType)) {
      this.nextStep.emit();
    } else {
      console.error('Nincs kivalasztott source type[ilyen nem lehetne!]');
    }
  }

  onClickMore() {
    this.collapsed = false;
  }

  ngAfterViewInit() {
    this.matCardContent.changes.pipe(untilDestroyed(this)).subscribe(() => this.checkAndSetHasCollapsed());
  }

  private checkAndSetHasCollapsed() {
    this.hasCollapsed = !isNil(this.matCardContent.first) ? this.matCardContent.first.nativeElement.offsetHeight > 160 : false;
  }
}
