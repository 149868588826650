<ng-container *transloco="let t; read: 'PERIODICAL.STATUS'">
  <div *ngIf="status" fxLayout="row" fxLayoutGap="1em" style="place-content: space-evenly">
    <div class="mat-elevation-z4 card w-100 total">
      <div class="icon-wrapper">
        <mat-icon svgIcon="sigma"></mat-icon>
      </div>
      <div class="d-inline-block text-left pl-2">
        <div class="mt-1">{{ t('LABEL.TOTAL') }}</div>
        <div>{{ t('VALUE', { value: status.total }) }}</div>
      </div>
    </div>
    <div class="mat-elevation-z4 card w-100 success">
      <div class="icon-wrapper">
        <mat-icon svgIcon="check"></mat-icon>
      </div>
      <div class="d-inline-block text-left pl-2">
        <div class="mt-1">{{ t('LABEL.SUCCESS') }}</div>
        <div>{{ t('VALUE', { value: status.success }) }}</div>
      </div>
    </div>
    <div class="mat-elevation-z4 card w-100 progress">
      <div class="icon-wrapper">
        <mat-icon svgIcon="timer-sand"></mat-icon>
      </div>
      <div class="d-inline-block text-left pl-2">
        <div class="mt-1">{{ t('LABEL.PROGRESS') }}</div>
        <div>{{ t('VALUE', { value: status.progress }) }}</div>
      </div>
    </div>
    <div class="mat-elevation-z4 card w-100 error">
      <div class="icon-wrapper">
        <mat-icon svgIcon="alert"></mat-icon>
      </div>
      <div class="d-inline-block text-left pl-2">
        <div class="mt-1">{{ t('LABEL.ERROR') }}</div>
        <div>{{ t('VALUE', { value: status.error }) }}</div>
      </div>
    </div>
  </div>
</ng-container>
