import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition, SimpleSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { ApplicationSettingsState } from '@roadrecord/application-settings/state';
import { deepEqual, disableMenuDetectRouteChangeOption, simpleFadeInAnimation, ViewportService } from '@roadrecord/common/common';
import { DialogComponent, DialogOptionsModel } from '@roadrecord/dialog';
import {
  CheckModifiedManualForm,
  CheckModifiedMVPFormGuard,
  CheckNewOrLoadPlugin,
  ENTITY_SERVICE_TOKEN,
  HasPresenterDeletePlugin,
  HasPresenterSavePluginController,
  MaybeHandleHttpError,
  NotificationStrategy,
  PRESENTER_COMPONENT,
  PRESENTER_PLUGINS,
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PRESENTER_STATE_DEFAULT_PROVIDERS,
  PresenterSavePluginOptionsModel,
  PresenterSaveSaveDataCallbackStrategy,
  PresenterStateController,
  SaveModel,
  VIEW_COMPONENT,
  VIEW_MODEL_PLUGIN_RESET_STATE,
} from '@roadrecord/utils';
import { MessageDialogOptionsInterface, MessageDialogService, MessageDialogTypeEnum } from '@roadrecord/message-dialog';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { isEmptyString, isNil, isObject, isString } from '@roadrecord/type-guard';
import { Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { currentHeadOrOfficeModalWrapperType } from '../modal-wrapper/modal-wrapper/modal-wrapper.component';
import { PARTNER_SERVICE_TYPE_TOKEN, partnerServiceFactory } from '../partner-service.factory';
import { PartnerLocationHttpListResponseModel, PartnerService } from '../partner.service';
import { DataFormComponent } from './data-form/data-form.component';
import { checkIsValidCoordinate } from './function/check-is-valid-coordinate.function';
import { notFoundCoordinate } from './function/not-found-coordinate.function';
import { ViewModeEnum } from './model/view-mode.enum';
import { partnerDetailsRoutingWhenSaveAndNotResetStrategy } from './partner-details-routing-when-save-and-not-reset.strategy';
import { PoiMoveSaveModel } from './poimove-dialog/model/poi-move-save.model';
import { PoimoveDialogComponent } from './poimove-dialog/poimove-dialog.component';
import {
  LocationModel,
  PARTNER_FROM_GEOCODING_COPY_FIELDS_TOKEN,
  partnerFromGeocodingModel,
  PartnerModel,
  PartnerTypeEnum,
} from '@roadrecord/partner/model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapBoxComponent } from '@roadrecord/map-box';
import { FragmentDialogTypeEnum, getMetadataByTypesFunction } from '@roadrecord/fragment-dialog';
import { NotEntitiesBackgroundImageType } from '@roadrecord/grid';
import { environment } from '@roadrecord/environment';

let markerIds = 0;

export const viewResetStateFn = (
  viewComponent: DataFormComponent,
  presenterStateController: PresenterStateController<PartnerModel, number>
): PartnerModel => {
  return { type: presenterStateController.routerData.type } as PartnerModel;
};

export const presenterSaveOptions = new PresenterSavePluginOptionsModel({
  presenterSaveSaveDataCallbackStrategy: PresenterSaveSaveDataCallbackStrategy.BEFORE_RUN_PLUGIN,
  responseErrorCallback: () => 'handleFormError',
  // tslint:disable-next-line:object-literal-shorthand
  responseOkCallback: function (): string {
    // tslint:disable-next-line:no-invalid-this
    if (this.modifyMode === true) {
      return 'checkPartnerUseDestination';
    }

    // tslint:disable-next-line:no-invalid-this
    if (this.routingMode === false) {
      return 'componentModeSave';
    }
    // tslint:disable-next-line:no-invalid-this
    if (this.fromMap === true) {
      return 'navigateToMap';
    }
  },
  // tslint:disable-next-line
  routingWhenSaveAndNotReset: function (): boolean {
    if (this.modifyMode === true) {
      return false;
    }
    if (partnerDetailsRoutingWhenSaveAndNotResetStrategy.strategy !== undefined) {
      return partnerDetailsRoutingWhenSaveAndNotResetStrategy.strategy;
    }
    // tslint:disable-next-line:no-invalid-this
    if (!isNil(this.searchUrlParameterModel)) {
      return false;
    }
    // tslint:disable-next-line:no-invalid-this
    return !this.fromMap;
  },
});

@UntilDestroy()
@Component({
  selector: 'rr-partner-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: PartnerService,
      useFactory: partnerServiceFactory,
      deps: [ActivatedRoute, HttpClient, [new Optional(), PARTNER_SERVICE_TYPE_TOKEN]],
    },
    {
      provide: ENTITY_SERVICE_TOKEN,
      useExisting: PartnerService,
    },
    { provide: PRESENTER_COMPONENT, useValue: DetailsComponent },
    { provide: VIEW_COMPONENT, useValue: DataFormComponent },
    { provide: PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN, useValue: presenterSaveOptions },
    {
      // TODO type -t beallitani majd valami alapjan...
      provide: VIEW_MODEL_PLUGIN_RESET_STATE,
      useValue: viewResetStateFn,
    },
    { provide: PRESENTER_PLUGINS, useValue: PRESENTER_STATE_DEFAULT_PROVIDERS },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
  animations: [
    simpleFadeInAnimation,
    trigger('enterAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class DetailsComponent
  extends CheckModifiedManualForm<PartnerModel | TripReasonModel>
  implements
    OnInit,
    OnDestroy,
    HasPresenterSavePluginController<PartnerModel, DataFormComponent>,
    HasPresenterDeletePlugin,
    AfterViewChecked {
  @Select(ApplicationSettingsState.osmTileUrl)
  readonly osmTileUrl$: Observable<string>;
  readonly isNew$: Observable<boolean>;
  readonly editModel$: ReplaySubject<PartnerModel>;
  readonly loading$: ReplaySubject<boolean>;
  viewMode: ViewModeEnum;
  ViewModeEnum = ViewModeEnum;
  backButtonTooltipText: string;
  backButtonLabel: string;
  markers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] = [];
  coordinateLoadingMask = false;
  searchListResult: LocationModel[] = [];
  partnerTypeString: string;
  @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;
  @ViewChild('poimoveDialog', { static: true })
  poimoveDialog: PoimoveDialogComponent;
  @ViewChild('progressLoadingPanel', { static: true, read: ElementRef })
  progressLoadingPanel: ElementRef;
  @ViewChildren('dataFormCard', { read: ElementRef })
  dataFormCard: QueryList<MatCard>;
  poimoveDialogPois: LocationModel[];
  type: PartnerTypeEnum;
  PartnerTypeEnum = PartnerTypeEnum;
  newOrModifyFormTitle: string;
  searchMapSearchResult: LocationModel[] = [];
  searchMapSearchString = '';
  lastSelectedLocationModel: LocationModel;
  @Input()
  readonly routingMode: true | false = true;
  @Input()
  hasSubmitAndNew = true;
  @Input()
  readonly hasBackButton = true;
  @Input()
  hasSearchAddressBackButton = true;
  @Input()
  readonly searchOnMapSnackbarWeRefreshListDataPosition: MatSnackBarVerticalPosition;
  @Input()
  readonly controlBackButton = false;
  @Input()
  readonly dataFormShowAddTripReason = true;
  @Input()
  disableAllSelectNewOption = false;
  @Output()
  readonly cancel = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<PartnerModel>();
  @Input()
  dataFormTypeControlIsVertical = false;
  partnerPoiMapLoading = false;
  dataFormTypeControlLabelBlock = false;
  partnerTypeTranslateKey: string;
  /**
   * mapbox altal szolgaltatot terkep hasznalatat lehet vele bekapcsolni,
   * jelenleg nem hasznaljuk, de elofordulhat hogy fogjuk majd(american mode miatt kerult bele annak idejen)
   */
  mapboxMode = false;
  fragmentDialogSearchValue: string;
  searchOnMapNotFoundImage: NotEntitiesBackgroundImageType;
  @Input()
  windowEditModelId: number;
  @HostBinding('class.has-manual-modified-address')
  modifyMode = false;
  /**
   * Cím módosítás esetén a térképre rakunk egy áttetsző overlay felületet, plusz egy szöveget és egy frissítés gombot
   */
  showRefreshLayer = false;
  disableEventButtons = false;
  modifyAddressWithMoveMarker = false;
  private lastDataFormCardWidth = 0;
  private checkNewOrLoadController: CheckNewOrLoadPlugin<PartnerModel, number>;
  private fromMap = false;
  private selectedTripReason: TripReasonModel;
  private poimoveDialogRef: MatDialogRef<DialogComponent>;
  /**
   * ideiglenes tarolo amiben az eppen szerkesztett form allapotot merge-juk a jelenleg szerkesztett model-vel
   */
  private lastEditModel: PartnerModel;
  private checkDataFormCardWidth = false;
  private weRefreshListDataMatSnackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private loadModifyPoimoveDialog = false;
  private nextCheckChange = true;
  private runPoimoveDialog = false;
  private lastMarkers: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[];
  private searchByPartnerNameOrAddressSubscription: Subscription;
  /**
   * poimove dialog -nal kell ha cim modositasa nelkul nyomunk ra mert akkor a leszuras helyet kell atvenni...
   */
  private poimoveDialogLocation: [number, number];
  private lastLoadedType: PartnerTypeEnum;
  @ViewChild(MapBoxComponent) private mapBoxComponent: MapBoxComponent;

  constructor(
    private router: Router,
    private http: HttpClient,
    private location: Location,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    private viewportService: ViewportService,
    private translocoService: TranslocoService,
    @Host() readonly presenterStateController: PresenterStateController<PartnerModel, number>,
    private checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    private messageDialogService: MessageDialogService,
    private store: Store,
    private partnerService: PartnerService,
    @Inject(PARTNER_FROM_GEOCODING_COPY_FIELDS_TOKEN) private partnerFromGeocodingCopyFields: string[]
  ) {
    super();

    this.viewportService.breakpoint$.pipe(untilDestroyed(this)).subscribe(breakpoint => {
      this.nextCheckChange = true;
      if (breakpoint.mobile) {
        this.disableAllSelectNewOption = true;
      }
    });
    // modal wrapper mode miatt
    this.type = !isNil(route.snapshot.data.type) ? route.snapshot.data.type : currentHeadOrOfficeModalWrapperType.type;
    this.setPartnerTypeTranslate();
    this.editModel$ = this.presenterStateController.editModel$;
    this.loading$ = this.presenterStateController.loading$;
    this.isNew$ = this.initIsNewStream();
    this.checkNewOrLoadController = this.presenterStateController.get(CheckNewOrLoadPlugin);
    this.initEditStream();

    this.checkBackToMap();
    this.getFragmentDialogSearchParams();
  }

  get formModel(): PartnerModel | TripReasonModel {
    // return trip reason dialog datas
    const partnerValue = this.presenterStateController.formGroupLastValue.value;
    const partnerNewValue: PartnerModel = {} as PartnerModel;
    /**
     * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
     */
    // tslint:disable-next-line
    Object.entries(partnerValue).forEach(entry => (partnerNewValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
    return partnerNewValue;
  }

  get originalModel(): PartnerModel | TripReasonModel {
    if (this.presenterStateController.editModelLastValue && !this.presenterStateController.isNewLastValue) {
      const partnerValue = this.presenterStateController.editModelLastValue;
      const partnerNewValue: PartnerModel = {} as PartnerModel;
      /**
       * kis hack mert mi undefined-t hasznalunk de a material form null-t add vissza :( a formModel getternel
       */
      // tslint:disable-next-line
      Object.entries(partnerValue).forEach(entry => (partnerNewValue[entry[0]] = entry[1] === null ? undefined : entry[1]));
      // ez azert kell mert az eredeti modelben related object van viszont a form id-t tarol
      partnerNewValue.trip_reason = !isNil(partnerNewValue.trip_reason) ? (partnerNewValue.trip_reason.id as any) : undefined;

      if (this.fromMap === true && isNil(partnerNewValue.type)) {
        // fix: ha terkep oldalrol jonnek es ugy akarnak uj/mentes-t nyomni
        partnerNewValue.type = this.presenterStateController.routerData.type;
      }

      return partnerNewValue;
    } else {
      const model: Partial<PartnerModel> = {};
      if (this.fromMap === true && isNil(model.type)) {
        // fix: ha terkep oldalrol jonnek es ugy akarnak uj/mentes-t nyomni
        model.type = this.presenterStateController.routerData.type;
      }
      model.trip_reason = !isNil(this.dataForm.tripReasonControl.value) ? this.dataForm.tripReasonControl.value : undefined;
      return model as PartnerModel;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResizeWindow(event): void {
    if (this.presenterStateController.isNewLastValue !== true) {
      this.checkDataFormCardWidth = true;
    }
    this.checkDataFormCardWidth = true;
    this.cdr.markForCheck();
  }

  async ngOnInit(): Promise<any> {
    this.initPartnerTypeParameters();
    this.setSearchOnMapNotFoundBackgroundImage();

    await this.checkNewOrLoadController.checkIsNew(
      () => {
        this.viewMode = ViewModeEnum.DATAFORM;
        this.checkDataFormCardWidth = true;
      },
      [],
      undefined,
      NotificationStrategy.FIRST_COMBINE_STREAMS,
      false,
      this.windowEditModelId,
      this.routingMode
    );

    this.detectSaveAndNewAndResetMap();
  }

  // handleFormError():void{
  //   this.dataForm.setEditModelUIRestriction();
  // }

  /**
   *
   * @param dataModel {any}
   * @param saveModel {SaveModel<PartnerModel>}
   */
  checkPartnerUseDestination(dataModel: any, saveModel: SaveModel<PartnerModel>) {
    let trip_reason_id: any;
    this.checkModifiedMVPFormGuard.disableNextCheck = true;

    if (
      this.presenterStateController.editModelLastValue.hasOwnProperty('trip_reason') &&
      isObject(this.presenterStateController.editModelLastValue.trip_reason)
    ) {
      trip_reason_id = this.presenterStateController.editModelLastValue.trip_reason.id;
    } else {
      trip_reason_id = this.presenterStateController.editModelLastValue.trip_reason;
    }
    // Utazási cél változásának ellenőrzése
    if (trip_reason_id === dataModel.trip_reason || ['head-office-or-site', 'charging-station'].includes(this.partnerService.entityUrl)) {
      this.navigateBackToBaseUrl();
      /*
      this.router.navigate(['../'], { relativeTo: this.route });
*/
    } else {
      // Partnerek előfordulásának lekérdezése az utakban
      this.partnerService.getDestinationUsage(dataModel.id).subscribe(
        response => {
          const destination_ids = response['destination_ids'];
          // Nincs érintett út - visszanavigálunk a partner listás nézethez
          if (destination_ids.length === 0) {
            this.router.navigate(['../'], { relativeTo: this.route });
          } else {
            const dialogOptions: MessageDialogOptionsInterface = {
              id: null,
              enableOk: true,
              htmlMode: true,
              enableCancel: true,
              type: MessageDialogTypeEnum.INFORMATION,
              text: 'PARTNER.DESTINATION_USAGE_DIALOG.MESSAGE',
              textParams: { count: destination_ids.length },
              confirmLabel: 'PARTNER.DESTINATION_USAGE_DIALOG.ACTION.OK',
              cancelLabel: 'PARTNER.DESTINATION_USAGE_DIALOG.ACTION.CANCEL',
            };

            this.messageDialogService
              .open(dialogOptions)
              .afterClosed()
              .subscribe(({ result }: { result: boolean }) => {
                if (result === true) {
                  const payload = { trip_reason: saveModel.model.trip_reason };
                  this.http.patch(`${environment.apiUrl}destination/bulk-modify/?by_ids=${destination_ids.join(';')}`, payload).subscribe(
                    () => {
                      // Változások figyelésének kikapcsolása
                      this.checkModifiedMVPFormGuard.disableNextCheck = true;
                      this.navigateBackToBaseUrl();
                      /*
                      this.router.navigate(['../'], { relativeTo: this.route });
*/
                    },
                    error => {
                      MaybeHandleHttpError.maybeHandleHttpError(error);
                    }
                  );
                } else {
                  this.navigateBackToBaseUrl();
                  /*
                  this.router.navigate(['../'], { relativeTo: this.route });
*/
                }
              });
          }
        },
        error => {
          MaybeHandleHttpError.maybeHandleHttpError(error);
        }
      );
    }
  }

  private navigateBackToBaseUrl(): void {
    const cleanUrl = this.router.url.split('#')[0];
    if (cleanUrl.includes('month-activities/finalization-of-route')) {
      this.router.navigate([cleanUrl]);
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
  navigateToMap(dataModel: any, saveModel: SaveModel<PartnerModel>): void {
    if (saveModel.reset === false) {
      this.checkModifiedMVPFormGuard.disableNextCheck = true;
      this.router.navigate(['../map'], { relativeTo: this.route /*, replaceUrl: true */ });
    }
  }

  onDelete(): void {}

  onBack(): void {
    if (this.controlBackButton === false) {
      // Kesobb vigyazni kell mert ez most a partner import felol erkezoket kezeli le, ha mas BI kell akkor refactor
      if (this.fromMap === true) {
        this.location.back();
      } else {
        this.router.navigate(['../'], { relativeTo: this.route /*, replaceUrl: true */ });
      }
    } else {
      this.cancel.emit();
    }
  }

  onSaveSearchMap(locationModel: LocationModel): void {
    this.viewMode = ViewModeEnum.DATAFORM;
    let editModel: PartnerModel = this.presenterStateController.formGroupLastValue.value as PartnerModel;
    const editModelLastValue = this.presenterStateController.editModelLastValue;
    const id = isNil(editModelLastValue) || isNil(editModelLastValue.id) ? undefined : editModelLastValue.id;

    this.lastSelectedLocationModel = locationModel;
    editModel = partnerFromGeocodingModel(locationModel, this.partnerFromGeocodingCopyFields, editModel);
    editModel.id = id;

    if (this.markers !== undefined && this.markers.length > 0 && !checkIsValidCoordinate(editModel)) {
      editModel.longitude = this.markers[0].geometry.coordinates[0];
      editModel.latitude = this.markers[0].geometry.coordinates[1];
    }

    if (
      this.presenterStateController.isNewLastValue === false &&
      !isNil(editModelLastValue) &&
      !isNil(editModelLastValue.name) &&
      !isEmptyString(editModelLastValue.name)
    ) {
      // RROHU-1774 ha modositas van akkor a cegnevet megtartjuk
      editModel.name = editModelLastValue.name;
    }
    this.editModel$.next({ ...editModelLastValue, ...editModel });
    this.cdr.markForCheck();
  }

  onCancelSearchMap(): void {
    const editModel = this.presenterStateController.editModelLastValue;
    const hasModel = !isNil(editModel);
    const id = hasModel ? editModel.id : undefined;
    // azert kell mert van olyan eset amikor az isNew = true de megis van adatmodel
    if (!!id || hasModel === true) {
      if (checkIsValidCoordinate(editModel)) {
        this.markers = [
          {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [editModel.longitude, editModel.latitude] },
            properties: {
              id: markerIds++,
              title: editModel.name,
              content: editModel.address,
              draggable: this.presenterStateController.isNewLastValue,
              onDragEnd: event => {
                const lngLat = event.getLngLat();
                this.onDragendMap([lngLat.lng, lngLat.lat]);
              },
            },
          },
        ];
      }

      this.viewMode = ViewModeEnum.DATAFORM;
      this.cdr.markForCheck();
    } else if (id === undefined && hasModel === false) {
      this.onBack();
    }
  }

  onSubmit(model: SaveModel<PartnerModel>): void {
    if (!isNil(model.model.local_country)) {
      delete model.model.local_country;
    }
    if (
      this.dataForm.typeControl.value === PartnerTypeEnum.CHARGING_STATION ||
      this.routingMode === false ||
      this.type === PartnerTypeEnum.CHARGING_STATION
    ) {
      model.model.trip_reason = this.presenterStateController.formGroupLastValue.getRawValue().trip_reason as any;
    }
  }

  onDragendMap(coordinates: [number, number]): void {
    /*
    if (this.presenterStateController.isNewLastValue === false) {
      // #RROHU-3235
      return;
    }
*/

    if (this.runPoimoveDialog === true) {
      if (this.searchByPartnerNameOrAddressSubscription !== undefined) {
        this.searchByPartnerNameOrAddressSubscription.unsubscribe();
      }
      this.setLastContext();
    }
    this.runPoimoveDialog = true;
    this.lastMarkers = this.markers;
    this.markers = [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates },
        properties: {
          id: markerIds++,
          title: '',
          content: '',
          draggable: this.presenterStateController.isNewLastValue,
          onDragEnd: event => {
            const lngLat = event.getLngLat();
            this.onDragendMap([lngLat.lng, lngLat.lat]);
          },
        },
      },
    ];

    delete this.lastEditModel;
    this.coordinateLoadingMask = true;
    if (this.viewMode === ViewModeEnum.DATAFORM) {
      this.editModel$.pipe(take(1)).subscribe(editModel => {
        this.lastEditModel = {
          ...editModel,
          ...this.presenterStateController.formGroupLastValue.getRawValue(),
        };
      });
      this.presenterStateController.formGroupLastValue.disable();
    } else {
      this.searchMapSearchString = undefined;
    }
    this.cdr.markForCheck();

    this.searchByPartnerNameOrAddressSubscription = this.partnerService
      .searchByPartnerNameOrAddress(`${coordinates[1]},${coordinates[0]}`)
      .pipe(untilDestroyed(this))
      .subscribe(
        locations => {
          if (locations.results.length === 0) {
            this.setLastContext();
            this.matSnackBar.open(
              this.translocoService.translate('PARTNER.DETAILS.SEARCH_ON_MAP.SNACKBAR.NOT_FOUND_ADDRESS'),
              this.translocoService.translate('COMMON.ACTION.CLOSE_LOWER')
            );
          } else {
            if (this.viewMode === ViewModeEnum.MAPSEARCH) {
              this.searchListResult = locations.results;
              this.searchMapSearchResult = locations.results;

              const mapMarker = {
                type: 'Feature',
                geometry: { type: 'Point', coordinates },
                properties: {
                  id: markerIds++,
                  title: '',
                  content: '',
                  draggable: this.presenterStateController.isNewLastValue,
                  onDragEnd: event => {
                    const lngLat = event.getLngLat();
                    this.onDragendMap([lngLat.lng, lngLat.lat]);
                  },
                },
              };
              const isMapSearch = this.viewMode === ViewModeEnum.MAPSEARCH;
              if (this.searchListResult.length === 1) {
                const remoteLocation: LocationModel = locations.results[0];
                mapMarker.geometry.coordinates = [parseFloat(remoteLocation.longitude), parseFloat(remoteLocation.latitude)];
                if (!isMapSearch) {
                  mapMarker.properties.title = `${remoteLocation.name}`;
                  mapMarker.properties.content = remoteLocation.address;
                }
              } else {
                if (!isMapSearch) {
                  mapMarker.properties.title = this.translocoService.translate('PARTNER.DETAILS.POI_MAP.USER_MOVED_POI');
                  mapMarker.properties.content = '';
                }
              }
              this.markers = [mapMarker as GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>];
              this.coordinateLoadingMask = false;
              this.cdr.markForCheck();
            } else {
              this.modifyAddressWithMoveMarker = true;
              // DATAFORM eseten
              this.poimoveDialogLocation = coordinates;
              this.poimoveDialogPois = locations.results;
              this.cdr.markForCheck();
              this.poimoveDialogRef = this.matDialog.open(DialogComponent, {
                panelClass: 'rr-dialog',
                maxWidth: 650,
                width: '650px',
                minWidth: this.poimoveDialogPois.length > 1 ? 500 : 300,
                maxHeight: 300,
                autoFocus: false,
                data: {
                  contentTpl: this.poimoveDialog.poimoveFormTpl,
                  buttonsTpl: this.poimoveDialog.poimoveButtonsTpl,
                  translateKey: 'PARTNER.DETAILS.POI_MOVE_DIALOG.TITLE',
                  width: '650px',
                } as DialogOptionsModel,
              });

              this.poimoveDialogRef
                .beforeClosed()
                .pipe(untilDestroyed(this))
                .subscribe(val => {
                  delete this.poimoveDialogRef;
                  if (isNil(val) || (isString(val) && val.length === 0)) {
                    // cancel
                    this.setLastContext();
                    this.presenterStateController.formGroupLastValue.enable();
                  } else {
                    if (this.loadModifyPoimoveDialog === false) {
                      this.presenterStateController.loading$.next(false);
                      this.coordinateLoadingMask = false;
                    }
                  }
                  if (this.presenterStateController.formGroupLastValue.get('type').value === PartnerTypeEnum.CHARGING_STATION) {
                    this.presenterStateController.formGroupLastValue.get('trip_reason').disable();
                  }
                  this.cdr.markForCheck();
                });
            }
            this.runPoimoveDialog = false;
          }
        },
        error => MaybeHandleHttpError.maybeHandleHttpError(error, () => this.setLastContext())
      );
  }

  changeToMapSearchView($event?: Event): void {
    if (!isNil($event)) {
      this.mergeAndSaveEditModelAndFormGroupLastValue();
    }

    this.viewMode = ViewModeEnum.MAPSEARCH;
    this.searchListResult = this.searchMapSearchResult;
    this.cdr.markForCheck();
  }

  onModifyPoimoveDialog(poiMoveSaveModel: PoiMoveSaveModel): void {
    this.presenterStateController.formGroupLastValue.enable();
    this.loadModifyPoimoveDialog = true;
    this.coordinateLoadingMask = true;
    this.loading$.next(true);
    this.partnerPoiMapLoading = true;
    this.cdr.markForCheck();

    const copyFields = [...this.partnerFromGeocodingCopyFields];
    copyFields.splice(copyFields.indexOf('name'), 1);

    const currentMarker = this.markers[0];

    const selectedTripReason = !isNil(this.selectedTripReason)
      ? this.selectedTripReason
      : this.presenterStateController.formGroupLastValue.get('trip_reason').value;

    if (poiMoveSaveModel.withoutAddressChange === false) {
      copyFields.push('address');
      // cim modositas
      if (poiMoveSaveModel.data.is_valid) {
        this.presenterStateController.editModel$.next(
          partnerFromGeocodingModel(
            {
              ...poiMoveSaveModel.data,
              longitude: `${currentMarker.geometry.coordinates[0]}`,
              latitude: `${currentMarker.geometry.coordinates[1]}`,
            },
            copyFields,
            { ...this.lastEditModel, trip_reason: selectedTripReason }
          )
        );

        this.loading$.next(false);
        this.partnerPoiMapLoading = false;
        this.coordinateLoadingMask = false;
        this.loadModifyPoimoveDialog = false;
      } else {
        this.partnerService
          .getCoordinatesByAddress(poiMoveSaveModel.data)
          .pipe(untilDestroyed(this))
          .subscribe(
            location => {
              if (!isNil(location)) {
                // TODO ez itt elvileg nem fog bekovetkezni ??? nem az is valid valtozast kene vizsgalni ?
                if (deepEqual(poiMoveSaveModel.data, location)) {
                  this.weRefreshListDataMatSnackBarRef = this.matSnackBar.open(
                    this.translocoService.translate('PARTNER.DETAILS.SEARCH_ON_MAP.SNACKBAR.WE_REFRESH_LIST_DATA'),
                    this.translocoService.translate('COMMON.ACTION.CLOSE_LOWER')
                  );
                }

                this.loading$.next(false);
                this.partnerPoiMapLoading = false;
                this.coordinateLoadingMask = false;
                this.loadModifyPoimoveDialog = false;
                return this.presenterStateController.editModel$.next(
                  partnerFromGeocodingModel(
                    {
                      ...location,
                      longitude: `${currentMarker.geometry.coordinates[0]}`,
                      latitude: `${currentMarker.geometry.coordinates[1]}`,
                    } as any /* csak addig kell amig detailsben nem lesz meg az uj address */,
                    copyFields,
                    { ...this.lastEditModel, trip_reason: selectedTripReason }
                  )
                );
              }
              notFoundCoordinate(poiMoveSaveModel.data.name, this.translocoService, this.messageDialogService);
              this.loading$.next(false);
              this.partnerPoiMapLoading = false;
              this.coordinateLoadingMask = false;
              this.loadModifyPoimoveDialog = false;
            },
            error =>
              MaybeHandleHttpError.maybeHandleHttpError(error, () => {
                this.loading$.next(false);
                this.partnerPoiMapLoading = false;
                this.coordinateLoadingMask = false;
                this.loadModifyPoimoveDialog = false;
              })
          );
      }
    } else {
      // csak koordinata modositas
      this.presenterStateController.editModel$.next(
        partnerFromGeocodingModel(
          {
            longitude: `${this.poimoveDialogLocation[0]}`,
            latitude: `${this.poimoveDialogLocation[1]}`,
          } as LocationModel,
          [/*'local_country', 'country',*/ 'latitude', 'longitude'],
          { ...this.lastEditModel, trip_reason: selectedTripReason }
        )
      );
      delete this.poimoveDialogLocation;
      this.partnerPoiMapLoading = false;
      this.loading$.next(false);
      this.coordinateLoadingMask = false;
      this.loadModifyPoimoveDialog = false;
    }
    this.poimoveDialogRef.close(poiMoveSaveModel);
  }

  onSelectTripReason(tripReason: TripReasonModel): void {
    this.selectedTripReason = tripReason;
  }

  storeSearchMapSearchResult($event: LocationModel[]): void {
    this.searchMapSearchResult = $event;
  }

  storeSearchMapSearchString(searchString: string): void {
    this.searchMapSearchString = searchString;
  }

  ngAfterViewChecked(): void {
    if (this.checkDataFormCardWidth === true && this.nextCheckChange === true) {
      this.nextCheckChange = false;
      timer(200)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.dataFormCard.first !== undefined) {
            this.lastDataFormCardWidth = ((this.dataFormCard.first as ElementRef).nativeElement as HTMLElement).offsetWidth;
            if (this.lastDataFormCardWidth > 0) {
              if (this.lastDataFormCardWidth <= 466 && this.lastDataFormCardWidth > 397) {
                this.dataFormTypeControlLabelBlock = true;
              } else if (this.lastDataFormCardWidth <= 397) {
                this.dataFormTypeControlLabelBlock = false;
                this.dataFormTypeControlIsVertical = true;
              } else {
                this.dataFormTypeControlLabelBlock = false;
                this.dataFormTypeControlIsVertical = false;
              }
              const nativeElement = this.progressLoadingPanel.nativeElement;
              if (this.lastDataFormCardWidth <= 403) {
                nativeElement.classList.add('fix-actions-row-height');
              } else {
                nativeElement.classList.remove('fix-actions-row-height');
              }
              this.checkDataFormCardWidth = false;
              this.cdr.detectChanges();
            } else {
              this.nextCheckChange = true;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.weRefreshListDataMatSnackBarRef !== undefined) {
      this.weRefreshListDataMatSnackBarRef.dismiss();
    }
  }

  handleFormError(): void {
    this.dataForm.setEditModelUIRestriction();
  }

  onModifyAddressFields(isModified: boolean) {
    if (isModified === true && this.modifyAddressWithMoveMarker === false) {
      const partnerModifyValue: PartnerModel = {} as PartnerModel;

      Object.entries(this.presenterStateController.formGroupLastValue.value).forEach(
        entry => (partnerModifyValue[entry[0]] = entry[1] === null ? undefined : entry[1])
      );

      partnerModifyValue.latitude = null;
      partnerModifyValue.longitude = null;
      partnerModifyValue.local_country = '';
      partnerModifyValue.id = this.presenterStateController.editModelLastValue.id;

      this.editModel$.next(partnerModifyValue);

      this.markers = [];
      this.showRefreshLayer = true;
      this.disableEventButtons = true;
    }
    this.modifyAddressWithMoveMarker = false;
  }

  onClickRefreshButton(): void {
    const partnerAddressFormGroup = this.presenterStateController.formGroupLastValue;
    const address = `
        ${partnerAddressFormGroup.get('postcode').value}
        ${partnerAddressFormGroup.get('city').value}
        ${partnerAddressFormGroup.get('street').value}
        ${partnerAddressFormGroup.get('house_number').value}
      `;

    this.loading$.next(true);

    this.partnerService.searchByPartnerNameOrAddress(address).subscribe(
      response => {
        if (response.results.length === 0) {
          this.setLastContext();
          this.matSnackBar.open(
            this.translocoService.translate('PARTNER.DETAILS.SEARCH_ON_MAP.SNACKBAR.NOT_FOUND_ADDRESS'),
            this.translocoService.translate('COMMON.ACTION.CLOSE_LOWER')
          );
        } else {
          this.showRefreshLayer = false;

          const firstAddressData = response.results[0];
          const partnerModifyValue: PartnerModel = {} as PartnerModel;

          Object.entries(this.presenterStateController.formGroupLastValue.value).forEach(
            entry => (partnerModifyValue[entry[0]] = entry[1] === null ? undefined : entry[1])
          );

          partnerModifyValue.country = firstAddressData.country;
          partnerModifyValue.local_country = firstAddressData.local_country;
          partnerModifyValue.latitude = parseFloat(firstAddressData.latitude);
          partnerModifyValue.longitude = parseFloat(firstAddressData.longitude);
          partnerModifyValue.id = this.presenterStateController.editModelLastValue.id;

          this.editModel$.next(partnerModifyValue);
          this.loading$.next(false);
          this.disableEventButtons = false;

          this.addMarkerToMap(response);
        }
      },
      error =>
        MaybeHandleHttpError.maybeHandleHttpError(error, () => {
          this.setLastContext();
          this.loading$.next(false);
        })
    );
  }

  private setSearchOnMapNotFoundBackgroundImage() {
    if (this.type === PartnerTypeEnum.PARTNER) {
      this.searchOnMapNotFoundImage = 'partnerek';
    } else if (this.type === PartnerTypeEnum.HEAD_OFFICE_OR_SITE) {
      this.searchOnMapNotFoundImage = 'hq-szines';
    } else {
      this.searchOnMapNotFoundImage = 'toltoallomasok';
    }
  }

  private getFragmentDialogSearchParams() {
    this.fragmentDialogSearchValue = getMetadataByTypesFunction(this.store, [
      FragmentDialogTypeEnum.NEW_HEAD_OR_OFFICE_SITE,
      FragmentDialogTypeEnum.NEW_PARTNER,
      FragmentDialogTypeEnum.NEW_CHARGING_STATION,
    ]);
  }

  private setPartnerTypeTranslate(): void {
    if (this.type !== undefined && this.lastLoadedType !== this.type) {
      this.lastLoadedType = this.type;
      this.partnerTypeTranslateKey = `PARTNER.TYPE.${PartnerTypeEnum.toString(this.type)}`;
    }
  }

  private detectSaveAndNewAndResetMap() {
    this.presenterStateController.refreshModelCommand$.pipe(untilDestroyed(this)).subscribe(() => {
      this.markers = [];
      this.mapBoxComponent.resetDefault();
    });
  }

  private setLastContext(): void {
    if (this.viewMode === ViewModeEnum.DATAFORM) {
      this.presenterStateController.formGroupLastValue.enable();
      this.presenterStateController.editModel$.next({
        ...this.lastEditModel,
        trip_reason: this.presenterStateController.editModelLastValue.trip_reason,
      });
    }
    this.coordinateLoadingMask = false;
    this.presenterStateController.loading$.next(false);

    this.runPoimoveDialog = false;
    this.cdr.markForCheck();
  }

  private initEditStream(): void {
    let _first = true;
    this.editModel$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(model => {
      if (model !== undefined) {
        if (checkIsValidCoordinate(model)) {
          this.setMarkerFromDataModel(false, _first, model);
          if (_first === true) {
            _first = false;
          }
        }
        /*
        else {
          notFoundCoordinate(model.name, this.translocoService, this.messageDialogService);
        }
*/
      } else {
        // Reset
        this.searchMapSearchString = undefined;
        this.searchMapSearchResult = undefined;
        this.lastSelectedLocationModel = undefined;
        this.changeToMapSearchView();
      }
    });
  }

  private initIsNewStream(): Observable<boolean> {
    return this.presenterStateController.isNew$.pipe(
      tap(isNew => {
        this.modifyMode = !isNew;
        this.setPartnerTypeTranslate();
        this.backButtonTooltipText = 'COMMON.ACTION.TOOLTIP.CANCEL';
        this.backButtonLabel = 'COMMON.ACTION.CANCEL';
        this.newOrModifyFormTitle = `PARTNER.DETAILS.TITLE.${isNew ? 'NEW' : 'MODIFY'}`;
      })
    );
  }

  private initPartnerTypeParameters(): void {
    if (this.type === undefined) {
      this.type = this.route.snapshot.data.type;
    }

    this.setPartnerTypeTranslate();
  }

  /**
   * layout valtasnal eltaroljuk a jelenlegi form allapotot, hogy amikor vissza valtunk akkor
   * megmaradjanak a valtozasok
   */
  private mergeAndSaveEditModelAndFormGroupLastValue(): void {
    this.lastEditModel = {
      ...this.presenterStateController.editModelLastValue,
      ...this.presenterStateController.formGroupLastValue.getRawValue(),
      trip_reason: this.selectedTripReason,
    };
  }

  private checkBackToMap(): void {
    if (!isNil(this.route.snapshot.queryParams.fromMap)) {
      this.fromMap = true;
    }
  }

  private setMarkerFromDataModel(_animate = false, _first = false, model?: PartnerModel): void {
    const editModel = model ? model : this.presenterStateController.editModelLastValue;
    const setMarkers = () =>
      (this.markers = [
        {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [editModel.longitude, editModel.latitude] },
          properties: {
            id: markerIds++,
            title: editModel.name,
            content: editModel.address,
            draggable: this.presenterStateController.isNewLastValue,
            onDragEnd: event => {
              const lngLat = event.getLngLat();
              this.onDragendMap([lngLat.lng, lngLat.lat]);
            },
          },
        },
      ]);
    setMarkers();
  }

  private componentModeSave(newPartner: PartnerModel): void {
    this.save.emit(newPartner);
  }

  private addMarkerToMap(data: PartnerLocationHttpListResponseModel): void {
    const coordinates = [parseFloat(data.results[0].longitude), parseFloat(data.results[0].latitude)];

    const mapMarker = {
      type: 'Feature',
      geometry: { type: 'Point', coordinates },
      properties: {
        id: markerIds++,
        title: '',
        content: '',
        draggable: this.presenterStateController.isNewLastValue,
        onDragEnd: event => {
          const lngLat = event.getLngLat();
          this.onDragendMap([lngLat.lng, lngLat.lat]);
        },
      },
    };
    const isMapSearch = this.viewMode === ViewModeEnum.MAPSEARCH;
    if (this.searchListResult.length === 1) {
      const remoteLocation: LocationModel = data.results[0];

      mapMarker.geometry.coordinates = [parseFloat(remoteLocation.longitude), parseFloat(remoteLocation.latitude)];

      if (!isMapSearch) {
        mapMarker.properties.title = `${remoteLocation.name}`;
        mapMarker.properties.content = remoteLocation.address;
      }
    } else {
      if (!isMapSearch) {
        mapMarker.properties.title = this.translocoService.translate('PARTNER.DETAILS.POI_MAP.USER_MOVED_POI');
        mapMarker.properties.content = '';
      }
    }
    this.markers = [mapMarker as GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>];
    this.coordinateLoadingMask = false;
    this.cdr.markForCheck();
  }
}
