import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { isNil } from '@roadrecord/type-guard';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '@roadrecord/environment';

@Injectable()
export class ReportPrintService {
  constructor(private http: HttpClient, private gtmService: GoogleTagManagerService) {}

  /**
   * Report nyomtatásához szükséges gépjármű adfatok elleneőrzése
   * @param vehicle_id {number}
   */
  getVehicleDataCheck(vehicle_id?: number, year?: number, month?: number) {
    const option = {};

    if (!isNil(vehicle_id) && !isNil(year) && !isNil(month)) {
      option['params'] = {
        user_state_context: JSON.stringify({ vehicle_id: vehicle_id, year: year, month: month }),
      };
    }
    return this.http.get<{ printing_allowed: boolean }>(`${environment.apiUrl}document-data-checker/vehicle-data-check/`, option);
  }

  /**
   * @param force csak mw-ben hasznaljuk
   * @param vehicle_id {number} gépjármű azonosító
   * @param year {number} kiválasztott év
   * @param month {number} kiválasztott hónap
   */
  getDocument(force: boolean, vehicle_id?: number, year?: number, month?: number) {
    const option = {};

    if (!isNil(vehicle_id) && !isNil(year) && !isNil(month)) {
      option['params'] = {
        user_state_context: JSON.stringify({ vehicle_id: vehicle_id, year: year, month: month }),
      };
    }

    return this.http.get<{ UUID: string }>(`${environment.api2Url}generate-document/${force ? 'force/' : ''}`, option).pipe(
      switchMap(response =>
        this.http
          .get<HttpResponse<Blob>>(environment.ms.pdfPrinter, {
            headers: {
              Accept: 'application/pdf',
            },
            params: { id: response.UUID, 'ngsw-bypass': 'true' },
            observe: 'response',
            responseType: 'blob' as 'json',
          })
          .pipe(
            catchError(error => {
              this.gtmService.pushTag({
                event: `PDF Hiba => status: ${!isNil(error.status) ? error.status : 'Ismeretlen status'} | statusText: ${
                  !isNil(error.statusText) ? error.statusText : 'Ismeretlen status uzenet'
                } | message: ${!isNil(error.message) ? error.message : 'Nincs hiba uzenet'}, UUID: ${response.UUID}`,
                category: 'pdfCategory',
              });
              return throwError(error);
            })
          )
      )
    );
  }

  getExcelOrCsv(isExcel: boolean) {
    return this.http.get<{ UUID: string }>(`${environment.api2Url}generate-${isExcel ? 'excel' : 'csv'}-report/`).pipe(
      switchMap(response =>
        this.http
          .get<HttpResponse<Blob>>(environment.ms.excelOrCsvPrinter, {
            headers: {
              Accept: isExcel ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv',
            },
            params: { id: response.UUID, 'ngsw-bypass': 'true', type: isExcel ? 'EXCEL' : 'CSV' },
            observe: 'response',
            responseType: 'blob' as 'json',
          })
          .pipe(
            catchError(error => {
              this.gtmService.pushTag({
                event: `${isExcel ? 'Excel' : 'Csv'} Hiba => status: ${
                  !isNil(error.status) ? error.status : 'Ismeretlen status'
                } | statusText: ${!isNil(error.statusText) ? error.statusText : 'Ismeretlen status uzenet'} | message: ${
                  !isNil(error.message) ? error.message : 'Nincs hiba uzenet'
                }, UUID: ${response.UUID}`,
                category: 'pdfCategory',
              });
              return throwError(error);
            })
          )
      )
    );
  }

  /**
   * Summary Report lekérdezése
   * @param vehicle_id {number} Gépjármű azonosító
   * @param start_of_period {string} Periódus kezdete
   * @param end_of_period {string} Periódus kezdete
   */
  getSummaryDocument(vehicle_id: number, start_of_period: string, end_of_period: string) {
    const params = new HttpParams()
      .set('vehicle_id', `${vehicle_id}`)
      .set('start_of_period', start_of_period)
      .set('end_of_period', end_of_period);

    return this.http
      .get<{ UUID: string }>(`${environment.api2Url}generate-period-summary-report/`, { params: params })
      .pipe(
        switchMap(response =>
          this.http
            .get<HttpResponse<Blob>>(environment.ms.pdfPrinter, {
              headers: {
                Accept: 'application/pdf',
              },
              params: { id: response.UUID, 'ngsw-bypass': 'true' },
              observe: 'response',
              responseType: 'blob' as 'json',
            })
            .pipe(
              catchError(error => {
                this.gtmService.pushTag({
                  event: `PDF Hiba => status: ${!isNil(error.status) ? error.status : 'Ismeretlen status'} | statusText: ${
                    !isNil(error.statusText) ? error.statusText : 'Ismeretlen status uzenet'
                  } | message: ${!isNil(error.message) ? error.message : 'Nincs hiba uzenet'}, UUID: ${response.UUID}`,
                  category: 'pdfCategory',
                });
                return throwError(error);
              })
            )
        )
      );
  }

  generateFreshbookReport() {
    return this.http.get(`${environment.api2Url}generate-freshbooks-report/`);
  }
}
