import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

export interface MatchesViewportSizes {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  custom: boolean;
}
// const logScopeName = 'ViewportService';
/**
 * Ez a service a kepernyo meret detectalasara es egyeb kepernyo muveletekre van
 * a service breakpoint$ valtozojara tudunk feliratkozni ahol egy MatchesViewportSizes -nak megfelelo interfacet kapunk
 * vissza a stream vegen ha valtozik a kepernyo meret(feliratkozasnal az utoslo allapotot mindig megkapjuk)
 */
@Injectable({ providedIn: 'root' })
export class ViewportService {
  private hasRouteAnimateClassOnBody = false;
  private removeAnimateClassFromBodyTimerSubscription: Subscription;
  private documentBodyRouterAnimateClass$ = new Subject<boolean>();
  private removeClassSubscription: Subscription;
  readonly openVideoDialog$ = new Subject<string>();
  private _isMobile = false;

  private CUSTOM_BREAKPOINT = '(max-width: 1366px)';

  constructor(breakpointObserver: BreakpointObserver, @Inject(DOCUMENT) private readonly document: Document) {
    this.watchBreakpointChanges(breakpointObserver);

    this.documentBodyRouterAnimateClass$
      .pipe(
        distinctUntilChanged(),
        // debounceTime(250),
        tap(
          () =>
            this.removeAnimateClassFromBodyTimerSubscription !== undefined &&
            !this.removeAnimateClassFromBodyTimerSubscription.closed &&
            this.removeAnimateClassFromBodyTimerSubscription.unsubscribe()
        )
      )
      .subscribe(hasClass => {
        // console.info(logScopeName, 'has class: ', hasClass);
        if (this.removeClassSubscription !== undefined && this.removeClassSubscription.closed === false) {
          this.removeClassSubscription.unsubscribe();
        }
        if (hasClass === true && this.hasRouteAnimateClassOnBody === false) {
          this.document.body.classList.add('router-animate');
          this.hasRouteAnimateClassOnBody = true;
        } else if (hasClass === false && this.hasRouteAnimateClassOnBody === true) {
          this.removeClassSubscription = timer(600).subscribe(() => {
            this.document.body.classList.remove('router-animate');
          });
          this.hasRouteAnimateClassOnBody = false;
          // this.removeAnimateClassFromBodyTimerSubscription = new TimerObservable(100)
          //     .subscribe(
          //         () => {
          //             this.document.body.classList.remove('router-animate');
          //             this.hasRouteAnimateClassOnBody = false;
          //         }
          //     );
        }
        // console.info(logScopeName, 'hasRouteAnimateClassOnBody', this.hasRouteAnimateClassOnBody);
      });
  }

  private _breakpoint$: Observable<MatchesViewportSizes>;

  /**
   * viewport meret valtozas jelzo stream
   */
  get breakpoint$(): Observable<MatchesViewportSizes> {
    return this._breakpoint$;
  }

  private _sidenavToggle = new EventEmitter<boolean | void>();

  /**
   * sima event szal, hogy ossze lehessen kotni a sidenav-t az app layout-val
   */
  get sidenavToggle(): EventEmitter<boolean | void> {
    return this._sidenavToggle;
  }

  set routerAnimation(run: boolean) {
    this.documentBodyRouterAnimateClass$.next(run);
  }

  private watchBreakpointChanges(breakpointObserver: BreakpointObserver): void {
    const mapFactory = (name: string) => {
      return map<BreakpointState, MatchesViewportSizes>(result => {
        const newObj = {};
        newObj[name] = result.matches;
        return newObj as MatchesViewportSizes;
      });
    };

    this._breakpoint$ = combineLatest([
      breakpointObserver.observe([Breakpoints.Large]).pipe(mapFactory('desktop')),
      breakpointObserver.observe([Breakpoints.XLarge]).pipe(mapFactory('desktop')),
      breakpointObserver.observe([Breakpoints.Web]).pipe(mapFactory('desktop')),
      breakpointObserver.observe([Breakpoints.Tablet]).pipe(mapFactory('tablet')),
      breakpointObserver.observe([Breakpoints.Handset]).pipe(mapFactory('mobile')),
      breakpointObserver.observe([Breakpoints.Small]).pipe(mapFactory('mobile')),
      breakpointObserver.observe([Breakpoints.XSmall]).pipe(mapFactory('mobile')),
      breakpointObserver.observe([this.CUSTOM_BREAKPOINT]).pipe(mapFactory('custom')),
    ]).pipe(
      switchMap(values => {
        return of(
          values.reduce((currItem, newItem) => {
            return { ...currItem, ...newItem };
          }, {})
        );
      }),
      filter((val: MatchesViewportSizes) => {
        if (Object.values(val).findIndex(v => v === true) === -1) {
          val.desktop = true;
        }
        let foundOne = false;
        let toManyFound = false;
        Object.values(val).forEach(objVal => {
          if (objVal === true) {
            if (foundOne === true) {
              toManyFound = true;
            } else {
              foundOne = true;
            }
          }
        });
        return foundOne !== false && toManyFound === false;
      }),
      tap(breakpoint => {
        this._isMobile = breakpoint.mobile && breakpoint.desktop === false;
      }),
      shareReplay(1)
    );
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  set isMobile(value: boolean) {
    this._isMobile = value;
  }
}
