import { ChangeDetectorRef, Component, ElementRef, HostBinding, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { fadeInEnter } from '@roadrecord/animations';
import { baseNotAccessUrl, MenuService } from '@roadrecord/app-layout/common';
import {
  BASIC_DATA_PAGE_PATH,
  deepEqual,
  FINALIZATION_OF_ROUTE_ROUTE_PATH,
  MONTH_ACTIVITIES_PAGE_PATH,
  RECOMMENDATION_ROUTE_PATH,
  RRValidators,
  SPECIAL_DAYS_ROUTE_PATH,
  VEHICLE_PARTNER_JOIN_PATH,
} from '@roadrecord/common/common';
import {
  CheckModifiedManualForm,
  CheckModifierCustomImplementationSubmittedAction,
  CheckModifierSubmitAction,
  commonHttpStreamErrorHandler,
} from '@roadrecord/utils';
import { isNil, isNumeric, isObject } from '@roadrecord/type-guard';
import { rrFormErrorStateMatcher, ValidationMessageModel } from '@roadrecord/validating';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, startWith, take, tap } from 'rxjs/operators';
import { RecommendationService } from '../recommendation.service';
import { RecommendationPeriodicalModel, RecommendationSettingsModel } from './model/recommendation-settings.model';
import { SETTLEMENT_SETTINGS_ENUM } from './model/settlement-settings.enum';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DecimalPipe } from '@angular/common';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';
import { isPrivatePeriodContextFn } from '../is-private-period-context';
import { isSelfEmployedPeriodContextFn } from '../is-self-employed-period-context';
import { AppTypeEnum, environment } from '@roadrecord/environment';
import moment from 'moment';
import { CompanyContextState, SubscriptionInfoEnum } from '@roadrecord/company-context/common';
import { PayoffTypeEnum } from '@roadrecord/vehicle/model/us';
import { PeriodicalService } from './periodical-list/periodical.service';

enum RecommendationPayOffFrequencyEnum {
  WEEKLY = 0,
  MONTHLY = 1,
}

interface RecommendationPayOffFrequencyModel {
  key: number;
  value: string;
}

export function numberOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = /^[0-9]*$/.test(control.value); // Csak számokat enged
    return isValid ? null : { numberOnly: true };
  };
}

@UntilDestroy()
@Component({
  selector: 'rr-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [RecommendationService, rrFormErrorStateMatcher, PeriodicalService],
  animations: [fadeInEnter],
})
export class SettingsComponent extends CheckModifiedManualForm<RecommendationSettingsModel | string> implements OnInit {
  readonly SETTLEMENT_SETTINGS_ENUM = SETTLEMENT_SETTINGS_ENUM;
  // 2-5
  readonly minControlSettings = Array.from({ length: 4 }, (v, k) => k + 2);
  // 3-15
  readonly maxControlSettings = Array.from({ length: 13 }, (v, k) => k + 3);
  readonly partnerDistanceUsageRuleControlSettings = [
    { key: 'RECOMMENDATION.SETTINGS.DISTANCE_USAGE_RULE.VALUE._0', value: 0 },
    { key: 'RECOMMENDATION.SETTINGS.DISTANCE_USAGE_RULE.VALUE._1', value: 1 },
    { key: 'RECOMMENDATION.SETTINGS.DISTANCE_USAGE_RULE.VALUE._2', value: 2 },
  ];
  readonly privateDestinationsUsageRuleControlSettings = [
    { key: 'RECOMMENDATION.SETTINGS.DESTINATION_USAGE_RULE.VALUE._0', value: 0 },
    { key: 'RECOMMENDATION.SETTINGS.DESTINATION_USAGE_RULE.VALUE._1', value: 1 },
    { key: 'RECOMMENDATION.SETTINGS.DESTINATION_USAGE_RULE.VALUE._2', value: 2 },
  ];
  readonly partnerMonthlyRecurrenceControlSettings = [
    { key: 'RECOMMENDATION.SETTINGS.MONTHLY_RECURRENCE.VALUE._0', value: 0 },
    { key: 'RECOMMENDATION.SETTINGS.MONTHLY_RECURRENCE.VALUE._1', value: 1 },
    { key: 'RECOMMENDATION.SETTINGS.MONTHLY_RECURRENCE.VALUE._2', value: 2 },
    { key: 'RECOMMENDATION.SETTINGS.MONTHLY_RECURRENCE.VALUE._3', value: 3 },
  ];
  readonly loading$ = new BehaviorSubject(true);
  readonly firstLoading$: Observable<boolean> = this.loading$.pipe(
    filter(v => v === false),
    take(1),
    startWith(true)
  );
  form: FormGroup & { submitted?: boolean };
  @HostBinding('class.is-private') isPrivateCar: boolean;
  isSelfEmployedCar: boolean;
  readonly privateDistanceRateControl = new FormControl(
    100,
    Validators.compose([
      Validators.required,
      RRValidators.min(0, this._decimalPipe.bind(this)),
      RRValidators.max(100, this._decimalPipe.bind(this)),
    ])
  );
  readonly companyDistanceRateControl = new FormControl(
    0,
    Validators.compose([
      Validators.required,
      RRValidators.min(0, this._decimalPipe.bind(this)),
      RRValidators.max(100, this._decimalPipe.bind(this)),
    ])
  );
  readonly payoffTypeControl = new FormControl(SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE, Validators.required);
  readonly costValueControl = new FormControl(
    undefined,
    Validators.compose([Validators.required, RRValidators.min(1, this._decimalPipe.bind(this))])
  );
  readonly minControl = new FormControl(-1, Validators.compose([Validators.required, RRValidators.max(5, this._decimalPipe.bind(this))]));
  readonly maxControl = new FormControl(-1, Validators.compose([Validators.required, RRValidators.max(15, this._decimalPipe.bind(this))]));
  readonly partnerDistanceUsageRuleControl = new FormControl(0, Validators.required);
  readonly privateDestinationsUsageRuleControl = new FormControl(0, Validators.required);
  readonly partnerMonthlyRecurrenceControl = new FormControl(0, Validators.required);
  readonly countOfDestinationGroup = new FormGroup(
    {
      min: this.minControl,
      max: this.maxControl,
    },
    this.minMaxFieldsValidator()
  );
  readonly maxOfDistanceControl = new FormControl(
    { value: undefined, disabled: true },
    Validators.compose([Validators.required, RRValidators.min(1, this._decimalPipe.bind(this))])
  );

  readonly countOfDestinationGroupErrorMessages: ValidationMessageModel[] = [
    { errorKey: 'minMaxError', translateKey: 'RECOMMENDATION.SETTINGS.VALIDATION.MIN_MAX' },
  ];
  readonly useMaxOfDistanceControl = new FormControl(false);
  readonly costNumberMask = { mask: globalImaskNumberConfigGenerator({ scale: 0, max: 999999 }) };
  readonly maxOfDistanceNumberMask = { mask: globalImaskNumberConfigGenerator({ scale: 0, max: 9999 }) };
  readonly payoffFrequencyControl = new FormControl(null, Validators.required);
  readonly isHUAppType = environment.appType === AppTypeEnum.HU;
  readonly companyDistanceControl = new FormControl(0, [Validators.required, Validators.min(0)]);
  readonly privateDistanceControl = new FormControl(0, [Validators.required, Validators.min(0)]);
  recommendationPayOffFrequencies: RecommendationPayOffFrequencyModel[] = [];
  workDaysRoute = ['/', MONTH_ACTIVITIES_PAGE_PATH, SPECIAL_DAYS_ROUTE_PATH];
  vehiclePartnerJoinRoute = ['/', BASIC_DATA_PAGE_PATH, VEHICLE_PARTNER_JOIN_PATH];

  periodicalForm: FormGroup & { submitted?: boolean };
  readonly calculationBaseControl = new FormControl(SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE, Validators.required);
  readonly distanceControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  readonly deductionControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  readonly startYearMonthControl = new FormControl(null, Validators.required);
  readonly endYearMonthControl = new FormControl(null, Validators.required);
  readonly maxOfPeriodCostNumberMask = { mask: globalImaskNumberConfigGenerator({ scale: 0, max: 999999 }) };
  readonly maxOfPeriodDistanceNumberMask = { mask: globalImaskNumberConfigGenerator({ scale: 0, max: 99999 }) };
  startDate: Date;
  endDate: Date;
  isTabPage2Disabled: boolean;
  isTabPage2Visible = false;
  private periodicalTableValue: number[] = [];
  private valueChangesSubscription: Subscription;

  private editModel: RecommendationSettingsModel;
  private checkModifyMode = false;

  selectedTabIndex = 0;

  @ViewChild('distanceInput') distanceInputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('deductionInput') deductionInputElement!: ElementRef<HTMLInputElement>;

  constructor(
    private recommendationService: RecommendationService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private menuService: MenuService,
    private injector: Injector,
    private translocoService: TranslocoService,
    private periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    private store: Store,
    private actions$: Actions,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private periodicalService: PeriodicalService
  ) {
    super();
    this.fillRecommendationPayOffFrequencies();
    this.checkPeriodContextChange();
    this.watchModifierSubmit();
  }

  /**
   *
   */
  get periodDetailsArray(): FormArray {
    return this.periodicalForm?.get('period_details') as FormArray;
  }

  get formModel(): RecommendationSettingsModel {
    const newModel: RecommendationSettingsModel = {} as RecommendationSettingsModel;
    Object.entries(this.form.value).forEach(entry => {
      if (!isNil(entry[1])) {
        if (isObject(entry[1])) {
          newModel[entry[0]] = {};
          Object.entries(entry[1]).forEach(_entry => {
            if (!isNil(_entry[1])) {
              newModel[entry[0]][_entry[0]] = _entry[1];
            }
          });
        } else {
          newModel[entry[0]] = entry[1];
        }
      }
    });
    return newModel;
  }

  get originalModel(): RecommendationSettingsModel {
    return this.editModel;
  }

  ngOnInit(): void {
    this.createForm();
    this.loadDataModel();
    this.watchPrivateAndCompanyRoadRateControlChanges();
    this.initPeriodicalPage();
  }

  onSubmit(redirectToRecommendation = false): void {
    if (this.selectedTabIndex === 1) {
      this.periodicalForm.submitted = true;
      if (this.periodicalForm.valid) {
        this.loading$.next(true);

        const formValue = this.periodicalForm.value;
        const payload: RecommendationPeriodicalModel = {
          ...formValue,
          start_of_period: formValue.start_of_period.format('YYYY-MM'),
          end_of_period: formValue.end_of_period.format('YYYY-MM'),
          period_details: Object.fromEntries(formValue.period_details.map(({ period, value }) => [period, value])),
        };

        this.periodicalService.startPeriodical(payload).subscribe(
          () => {
            this.loading$.next(false);
            this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${RECOMMENDATION_ROUTE_PATH}/list`], {});
          },
          commonHttpStreamErrorHandler(() => {
            this.loading$.next(false);
          })
        );
      }
    } else {
      this.form.submitted = true;
      if (this.form.valid) {
        const formValue = this.form.value;
        this.form.disable();
        this.loading$.next(true);
        this.recommendationService.saveSettings(formValue).subscribe(
          () => {
            this.matSnackBar.open(this.translocoService.translate('RECOMMENDATION.SETTINGS.PARAMETERS_SAVED'));
            if (this.checkModifyMode === true) {
              this.store.dispatch(new CheckModifierCustomImplementationSubmittedAction(true));
            } else {
              this.editModel = formValue;
              if (redirectToRecommendation === true) {
                // TODO ez miert kell ?
                if (this.useMaxOfDistanceControl.value === false) {
                  this.maxOfDistanceControl.reset();
                }
                this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`], {
                  queryParams: { startRecommendation: '' },
                });
              } else {
                this.form.enable();
                if (this.useMaxOfDistanceControl.value === false) {
                  this.maxOfDistanceControl.disable();
                }
                this.loading$.next(false);
              }
            }
          },
          commonHttpStreamErrorHandler(() => {
            this.form.enable();
            if (this.useMaxOfDistanceControl.value === false) {
              this.maxOfDistanceControl.disable();
            }
            this.loading$.next(false);
            if (this.checkModifyMode === true) {
              this.store.dispatch(new CheckModifierCustomImplementationSubmittedAction(false));
              this.checkModifyMode = false;
            }
          })
        );
      }
    }
  }

  minMaxFieldsValidator(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } => {
      const minControlValue = c.get('min').value;
      const maxControlValue = c.get('max').value;
      if (minControlValue === -1 || maxControlValue === -1) {
        return null;
      }
      if (minControlValue > maxControlValue) {
        c.get('min').setErrors({});
        c.get('max').setErrors({});
        return { minMaxError: true };
      } else {
        c.get('min').setErrors(null);
        c.get('max').setErrors(null);
      }
      return null;
    };
  }

  onBack(): void {
    this.router.navigate([`/${MONTH_ACTIVITIES_PAGE_PATH}/${FINALIZATION_OF_ROUTE_ROUTE_PATH}`]);
  }

  /**
   * MW oldalon vagyunk és van érvényes előfizetése
   * @private
   */
  private initPeriodicalPage(): void {
    if (!this.isHUAppType) {
      const state = this.store.selectSnapshot(this.periodContextStateSelectorsService.context);

      if (state.vehicle.payoff_type === PayoffTypeEnum.SMR) {
        const companyContext = this.store.selectSnapshot(CompanyContextState.subscription);
        this.endDate = moment().toDate();
        this.initPeriodicalForm();

        this.calculationBaseControl.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(value => {
          this.periodicalForm?.removeControl('period_details');
          this.periodicalForm?.removeControl('payoff_settings');
          this.distanceControl.patchValue(null, { emitEvent: false });
          this.deductionControl.patchValue(null, { emitEvent: false });

          const payoffValueControl = value === SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE ? this.distanceControl : this.deductionControl;

          const payoffSettingsGroup = new FormGroup({
            payoff_type: this.calculationBaseControl,
            payoff_value: payoffValueControl,
          });

          this.periodicalForm.setControl('payoff_settings', payoffSettingsGroup);
          this.periodicalForm.updateValueAndValidity({ emitEvent: false });

          this.rebuildPeriodicalFormChangesStream();
        });

        this.rebuildPeriodicalFormChangesStream();

        this.isTabPage2Visible = true;
        this.isTabPage2Disabled = companyContext.state === SubscriptionInfoEnum.NO_SUBSCRIPTION;
      }
    }
    this.loading$.next(false);
  }

  private rebuildPeriodicalFormChangesStream(): void {
    const startOfPeriodControl = this.periodicalForm.get('start_of_period');
    const endOfPeriodControl = this.periodicalForm.get('end_of_period');
    const payoffValueControl = this.periodicalForm.get('payoff_settings.payoff_value');

    this.valueChangesSubscription?.unsubscribe();

    this.valueChangesSubscription = merge(
      startOfPeriodControl.valueChanges,
      endOfPeriodControl.valueChanges,
      payoffValueControl.valueChanges
    )
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(() => {
        this.recalculatePeriods();
      });
  }

  private initPeriodicalForm(): void {
    this.periodicalForm = new FormGroup({
      start_of_period: this.startYearMonthControl,
      end_of_period: this.endYearMonthControl,
      payoff_settings: new FormGroup({
        payoff_type: this.calculationBaseControl,
        payoff_value: this.distanceControl,
      }),
    });
  }

  private recalculatePeriods(): void {
    if (this.periodicalForm.get('period_details')) {
      this.periodicalForm.removeControl('period_details');
    }

    if (this.periodicalForm.valid) {
      this.periodicalTableValue = [];
      const totalValue = +this.periodicalForm.get('payoff_settings.payoff_value').value;
      const startDate = moment(this.periodicalForm.get('start_of_period').value);
      const endDate = moment(this.periodicalForm.get('end_of_period').value);

      const months = endDate.diff(startDate, 'months') + 1;
      const baseValue = Math.floor(totalValue / months);
      const remainder = totalValue % months;

      const periodArray = this.fb.array(
        Array.from({ length: months }, (_, i) => {
          const date = startDate.clone().add(i, 'months').format('YYYY-MM');
          const value = baseValue + (i < remainder ? 1 : 0);
          this.periodicalTableValue.push(value);

          return this.fb.group({
            period: [date, Validators.required],
            value: [value, [Validators.required, Validators.min(0), Validators.max(+totalValue), numberOnlyValidator()]],
          });
        })
      );

      this.periodicalForm.setControl('period_details', periodArray);
      this.periodicalForm.get('period_details')?.updateValueAndValidity({ emitEvent: false });

      this.periodDetailsArray.controls.forEach((control, modifiedIndex) => {
        control.valueChanges.pipe(untilDestroyed(this), debounceTime(500)).subscribe(modifiedItem => {
          if (this.periodicalForm.valid) {
            const modifiedValue = +modifiedItem.value;
            const previousSum = periodArray.controls.slice(0, modifiedIndex).reduce((sum, ctrl) => sum + +ctrl.get('value').value, 0);
            const remainingSum = totalValue - modifiedValue - previousSum;

            const remainingControls = periodArray.controls.slice(modifiedIndex + 1);
            const aboveControls = periodArray.controls.slice(0, modifiedIndex);

            // Frissítsük az alatta lévő elemeket
            let remainingValue = remainingSum;
            remainingControls.forEach((ctrl, idx) => {
              const countRemaining = remainingControls.length - idx;
              const newValue = Math.max(0, Math.floor(remainingValue / countRemaining));
              ctrl.get('value')?.setValue(newValue, { emitEvent: false });
              remainingValue -= newValue;
            });

            // Ha van maradék és pozitív, frissítsük a felette lévő elemeket
            if (remainingValue > 0) {
              aboveControls.forEach((ctrl, idx) => {
                const countRemaining = aboveControls.length - idx;
                const currentValue = +ctrl.get('value').value;
                const newValue = Math.max(0, Math.floor(remainingValue / countRemaining));
                ctrl.get('value')?.setValue(currentValue + newValue, { emitEvent: false });
                remainingValue -= newValue;
              });
            }

            // Ha van maradék és negatív, frissítsük a felette lévő elemeket
            if (remainingValue < 0) {
              let deficit = Math.abs(remainingValue);
              const eligibleControls = aboveControls.filter(item => item.get('value').value > 0); // Csak nullánál nagyobb értékekkel dolgozunk
              const totalControls = eligibleControls.length;

              eligibleControls.forEach((ctrl, idx) => {
                const currentValue = +ctrl.get('value').value;

                // Egyenletes osztás, a maradék figyelembevételével
                const exactDecrement = deficit / (totalControls - idx); // Az aktuális osztási érték (egyenletes)
                const decrement = Math.min(currentValue, Math.round(exactDecrement)); // Kerekített érték, de max az aktuális elem értéke

                // Csökkentés
                ctrl.get('value')?.setValue(currentValue - decrement, { emitEvent: false });
                deficit -= decrement; // A deficitet csökkentjük az aktuális kivonással
              });
            }

            // Végleges validálás
            periodArray.controls.forEach(ctrl => {
              ctrl.get('value')?.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            });
          }
        });
      });
    }
  }

  getSumPeriodValues(): number {
    if (this.periodicalForm.valid) {
      const totalValue = this.periodDetailsArray?.controls.reduce((sum, control) => {
        const value = +control.get('value')?.value || 0;
        return sum + value;
      }, 0);
      return totalValue;
    }
    return;
  }

  private watchModifierSubmit() {
    this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(CheckModifierSubmitAction)).subscribe(() => {
      this.checkModifyMode = true;
      this.onSubmit();
    });
  }

  private loadDataModel(): void {
    this.loadRemoteModel();
  }

  private watchPrivateAndCompanyRoadRateControlChanges(): void {
    this.privateDistanceRateControl.valueChanges
      .pipe(
        filter(value => isNumeric(value)),
        distinctUntilChanged()
      )
      .subscribe(privateRoadRate => {
        this.companyDistanceRateControl.patchValue(100 - privateRoadRate, { emitEvent: false });
      });

    this.companyDistanceRateControl.valueChanges
      .pipe(
        filter(value => isNumeric(value)),
        distinctUntilChanged()
      )
      .subscribe(companyRoadRate => {
        this.privateDistanceRateControl.patchValue(100 - companyRoadRate);
      });
  }

  private loadRemoteModel(): void {
    this.recommendationService.getSettings().subscribe(
      settings => this.formBind(settings),
      commonHttpStreamErrorHandler(() => this.loading$.next(false))
    );
  }

  private checkPeriodContextChange(): void {
    this.store
      .select(this.periodContextStateSelectorsService.context)
      .pipe(
        distinctUntilChanged((_old, _new) => deepEqual(_old, _new)),
        tap(context => {
          this.isPrivateCar = isPrivatePeriodContextFn(context, this.periodContextStateSelectorsService);
          this.isSelfEmployedCar = isSelfEmployedPeriodContextFn(context, this.periodContextStateSelectorsService);
        }),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.loading$.next(true);
        this.menuService.hasMenuItemByUrl().subscribe(v => {
          if (v === true) {
            this.form.reset();
            delete this.form;
            this.createForm();
            this.loadRemoteModel();
          } else {
            this.router.navigate([baseNotAccessUrl]);
          }
        });
      });
  }

  private createForm(): void {
    const formGroup: any = {
      daily_settings: new FormGroup({
        count_of_destinations: this.countOfDestinationGroup,
        max_of_distance: this.maxOfDistanceControl,
      }),
    };

    if (!this.isPrivateCar) {
      // ceges
      formGroup.company_distance_rate = this.companyDistanceRateControl;
      /*
      formGroup.private_destinations_usage_rule = this.privateDestinationsUsageRuleControl;
      */
    } else {
      // magan
      formGroup.payoff_settings = new FormGroup({
        payoff_type: this.payoffTypeControl,
        cost_value: this.costValueControl,
      });

      if (this.isHUAppType === true) {
        formGroup.use_default_delegation_time = new FormControl(false);
      } else {
        formGroup.payoff_settings.addControl('company_distance', this.companyDistanceControl);
        formGroup.payoff_settings.addControl('private_distance', this.privateDistanceControl);
        formGroup.payoff_settings.addControl('payoff_frequency', this.payoffFrequencyControl);
      }
    }

    this.form = new FormGroup({
      ...formGroup,
      partner_distance_usage_rule: this.partnerDistanceUsageRuleControl,
      partners_monthly_recurrence: this.partnerMonthlyRecurrenceControl,
    });

    if (this.isHUAppType === false || !this.isPrivateCar) {
      this.form.addControl('private_destinations_usage_rule', this.privateDestinationsUsageRuleControl);
    }
  }

  private formBind(settings: RecommendationSettingsModel): void {
    this.editModel = settings;

    if (!isNil(settings.daily_settings.max_of_distance)) {
      this.useMaxOfDistanceControl.patchValue(true);
      this.maxOfDistanceControl.enable();
    }
    this.form.patchValue(settings);
    this.loading$.next(false);
  }

  private _decimalPipe() {
    return new DecimalPipe(this.translocoService.getActiveLang());
  }

  private fillRecommendationPayOffFrequencies(): void {
    for (const key of Object.values(RecommendationPayOffFrequencyEnum)) {
      if (!isNaN(Number(key))) {
        const valueText =
          key === RecommendationPayOffFrequencyEnum.WEEKLY
            ? 'RECOMMENDATION.SETTINGS.PAYOFF.FREQUENCY.WEEKLY'
            : 'RECOMMENDATION.SETTINGS.PAYOFF.FREQUENCY.MONTHLY';
        this.recommendationPayOffFrequencies.push({
          key: Number(key),
          value: this.translocoService.translate(valueText),
        });
      }
    }
  }
  onTabChange(event: any): void {
    if (event.index === 1) {
      setTimeout(() => {
        if (this.calculationBaseControl.value === SETTLEMENT_SETTINGS_ENUM.RAN_DISTANCE) {
          this.distanceInputElement.nativeElement.focus();
        } else {
          this.deductionInputElement.nativeElement.focus();
        }
      }, 250);
    }
  }
}
