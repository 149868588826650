import { Inject, Injectable } from '@angular/core';
import { STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { GetUserStatesAction, GetUserStatesErrorAction, GetUserStatesSuccessAction, UserCompanyMeState } from '@roadrecord/common/common';
import { IdleTimeoutAction } from '@roadrecord/idle-detect';
import { asapScheduler, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RRStorageEngine } from '../rr-storage.engine';
import { LogoutAction } from './action/logout/logout.action';
import { AuthStateModel } from './model/auth-state.model';
import { StateService } from './state.service';
import { UserSettingsState } from '@roadrecord/user/application-settings';
import { AppTypeEnum, environment } from '@roadrecord/environment';

@State<string>({
  name: 'user',
  children: [UserSettingsState, UserCompanyMeState],
})
@Injectable()
export class UserState {
  readonly isAmericanVersion = environment.appType === AppTypeEnum.US;
  constructor(
    private stateService: StateService,
    @Inject(STORAGE_ENGINE)
    private readonly storageEngine: RRStorageEngine
  ) {}

  @Action(GetUserStatesAction)
  getUserStates({ dispatch }: StateContext<AuthStateModel>): Observable<any> {
    if (this.isAmericanVersion === true) {
      return forkJoin({
        states: this.stateService.getAll(),
        hasSubscription: this.stateService.getSubscription(),
        hasAvailableSubscription: this.stateService.getPaymentTransaction(),
      }).pipe(
        tap(({ states, hasSubscription, hasAvailableSubscription }) => {
          // Itt dolgozzuk fel a states, hasSubscription és hasAvailableSubscription választ
          if (hasAvailableSubscription.count > 0 || hasSubscription.count > 0) {
            (states as any)['rr.company-context']['company']['subscription_state'] = 5;
          }
          asapScheduler.schedule(() => dispatch(new GetUserStatesSuccessAction(states)));
          this.addEmailAddressToHelpCrunchSettings(this.storageEngine.getUserId());
        }),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new GetUserStatesErrorAction(error)));
          return throwError(error);
        })
      );
    } else {
      return this.stateService.getAll().pipe(
        tap(states => {
          asapScheduler.schedule(() => dispatch(new GetUserStatesSuccessAction(states)));
          this.addEmailAddressToHelpCrunchSettings(this.storageEngine.getUserId());
        }),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new GetUserStatesErrorAction(error)));
          return throwError(error);
        })
      );
    }
  }

  @Action(IdleTimeoutAction)
  idleTimeout({ dispatch }: StateContext<AuthStateModel>) {
    this.storageEngine.setItem('auth', '{}');

    dispatch(new LogoutAction());
  }

  private addEmailAddressToHelpCrunchSettings(email: string): void {
    if (environment.production === false) {
      return;
    }
    if (window.hasOwnProperty('helpcrunchSettings')) {
      // @ts-ignore
      window.helpcrunchSettings.user = { email: email };
      if (window.hasOwnProperty('HelpCrunch')) {
        // @ts-ignore
        window.HelpCrunch('updateUser', { email: email });
      } else {
        console.error('HelpCrunch is not initialized');
      }
    } else {
      console.error('HelpCrunch is not initialized');
    }
  }
}
